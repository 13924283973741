import { useState, useEffect } from 'react';
import QRCode from "react-qr-code";
import Modal from 'react-bootstrap/Modal';

import FolderLayout from "../components/FolderLayout";
import Footer from "../components/Footer";
import Header from "../components/Header";

import iconClose from "../images/icons/close.png";
import iconSuccess from '../images/icons/success.png';

const MainDocument = ({ properties: p }) => {
    const [ docType, setDocType ] = useState('');
    const [ documentTypes, setDocumentTypes ] = useState([]);
    const [ referenceNumber, setReferenceNumber ] = useState('');
    const [ origin, setOrigin ] = useState('I');
    const [ departmentCode, setDepartmentCode ] = useState('');
    const [ modalDepartmentCode, setModalDepartmentCode ] = useState('');
    const [ departments, setDepartments ] = useState('');
    const [ names, setNames ] = useState([]);
    const [ messengers, setMessengers ] = useState([]);
    const [ messengerCode, setMessengerCode ] = useState('');
    const [ remarks, setRemarks ] = useState('');

    const [ title, setTitle ] = useState('');
    const [ description, setDescription ] = useState('');
    const [ notes, setNotes ] = useState('');

    const [ fname, setFname ] = useState('');
    const [ lname, setLname ] = useState('');
    const [ position, setPosition ] = useState('');
    const [ officeName, setOfficeName ] = useState('');
    const [ address, setAddress ] = useState('');
    const [ country, setCountry ] = useState('PH');
    const [ contactNumber, setContactNumber ] = useState('');

    const [ originUserID, setOriginUserID ] = useState('');

    const [showModal, setShowModal] = useState(false);
    const [showModalSuccess, setShowModalSuccess] = useState(false);

    useEffect( () => {
        fetch('/api/dts-main-document')
            .then( res => res.json() )
            .then( res => {
                if( res.document_types ) setDocumentTypes(res.document_types);
                if( res.departments ) setDepartments(res.departments);
            })
        ;
    }, [] );

    const handleDocTypeChange = (value) => {
        if( referenceNumber === "" && value !== "" ) {
            fetch(`/api/dts-main-document/generate-document-reference-number`)
                .then( res => res.json() )
                .then( res => {
                    if( res.reference_number ) { //
                        setReferenceNumber(res.reference_number);
                    }
                })
            ;
        }

        setDocType(value);
    }

    const handleDepartmentChange = (value) => {
        fetch(`/api/dts-main-document/get-list-of-names-under-department/${value}`)
            .then( res => res.json() )
            .then( res => {
                if( res.names ) setNames(res.names);
                else setNames([]);
            })
        ;

        setDepartmentCode(value);
    }

    const handleModalDepartmentChange = (value) => {
        fetch(`/api/dts-main-document/get-list-of-messengers/${value}`)
            .then( res => res.json() )
            .then( res => {
                if( res.names ) setMessengers(res.names);
                else setMessengers([]);
            })
        ;

        setModalDepartmentCode(value);
    }

    const handlePrintQRcode = () => {
        var content = document.getElementById("qrCodeBox");
        var winPrint = window.open('', 'Print', 'toolbar=0, location=0, scrollbars=1, resizable=1, width=100, height=125');

        winPrint.document.write(content.innerHTML);
        winPrint.document.close();
        winPrint.focus();
        winPrint.print();
        winPrint.close();
    }

    const handleSendDocument = () => {
        if( !docType ) return alert("Please select document type.");
        if( !referenceNumber ) return alert("No reference number!");
        
        const sendDetails = {
            docType,
            referenceNumber,
            title,
            description,
            notes,
            origin,
            departmentCode,
            originUserID,
            fname,
            lname,
            position,
            officeName,
            address,
            country,
            contactNumber,
            receiver_deptcd: modalDepartmentCode,
            messengerCode,
            remarks
        };
        
        fetch('/api/dts-main-document/forward-document',
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(sendDetails)
            }).then( res => res.json() )
            .then( res => {
                if( 'msg' in res ) {
                    setShowModalSuccess(true);

                    setShowModal(false);
                }
            })
        ;
    }

    const handleCloseSuccessModal = () => {
        clearAll();
        setShowModalSuccess(false)
    }

    const clearAll = () => {
        setDocType('');
        setReferenceNumber('');
        setOrigin('I');
        setDepartmentCode('');
        setModalDepartmentCode('');
        setNames([]);
        setMessengers([]);
        setMessengerCode('');
        setRemarks('');
        setTitle('');
        setDescription('');
        setNotes('');
        setFname('');
        setLname('');
        setPosition('');
        setOfficeName('');
        setAddress('');
        setCountry('PH');
        setContactNumber('');
        setOriginUserID('');
    }

    const modal = () => {
        return (
            <Modal show={showModal} onHide={ () => setShowModal(false) } backdrop="static" keyboard={false} centered>
                <Modal.Body>
                    <div className="text-align-right">
                        <input type="image" src={iconClose} onClick={ () => setShowModal(false) } />
                    </div>

                    <div className="d-flex justify-content-end align-items-center mt-3">
                        <label className="text-align-right">* Destination</label>
                        <select value={modalDepartmentCode} onChange={ (e) => handleModalDepartmentChange(e.target.value) } className="width-80 ml-2">
                            <option value=""></option>
                            { departments.length > 0 && departments.map( (d, i) => (
                                <option key={i} value={d.dept_code}>{d.dept_title}</option>
                            ) ) }
                        </select>
                    </div>
                    <div className="d-flex justify-content-end align-items-center mt-3">
                        <label className="text-align-right">* Messenger</label>
                        <select value={messengerCode} onChange={ (e) => setMessengerCode(e.target.value) } className="width-80 ml-2">
                            <option value=""></option>
                            { messengers.length > 0 && messengers.map( (n, i) => (
                                <option key={i} value={n.userid}>{n.lastname}, {n.firstname}</option>
                            ) ) }
                        </select>
                    </div>
                    <div className="d-flex justify-content-end align-items-center mt-3">
                        <label className="text-align-right">* Remarks</label>
                        <textarea value={remarks} onChange={ (e) => setRemarks(e.target.value) } rows="4" className="width-80 ml-2"></textarea>
                    </div>

                    <div className="d-flex justify-content-end align-items-center mt-3 mb-4">
                        <label></label>
                        <div className="width-80 ml-2">
                            <button onClick={handleSendDocument}>Send</button>
                            <button className="ml-2" onClick={ () => setShowModal(false) }>Cancel</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    };

    const modalSuccess = () => {
        return (
            <Modal show={showModalSuccess} onHide={ () => handleCloseSuccessModal(false) } backdrop="static" keyboard={false} centered>
                <Modal.Body>
                    <div className="text-align-right">
                        <input type="image" src={iconClose} onClick={ () => handleCloseSuccessModal(false) } />
                    </div>
                    <div className="mt-4 mb-3">
                        <div className="mb-2 text-align-center">
                            <img src={iconSuccess} alt="Success" />
                            <span className="ml-2 bold" style={{ color: 'rgb(0, 72, 255)', fontSize: '1.2rem' }}>Successfully forwarded the document</span>
                        </div>
                    </div>
                    <div className="mb-3 text-align-center">
                        <button className="ml-2" onClick={ () => handleCloseSuccessModal(false) }>Okay</button>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }

    return (
        <div className="container">
            <Header name={p.name} department={p.department} />
            
            <FolderLayout page="main-doc">
                <div className="main-doc">
                    <fieldset>
                        <legend>Document Reference Number</legend>
                        <div className="doc-ref-num">
                            <span className="mr-2">* Document Type:</span>
                            <select value={docType} onChange={ (e) => handleDocTypeChange(e.target.value) }>
                                <option value="">Please select main document</option>
                                { documentTypes.length > 0 && documentTypes.map( (dt, i) => (
                                    <option key={i} value={dt.doc_type_id}>{dt.descr}</option>
                                ) ) }
                            </select>
                        </div>
                        { referenceNumber &&
                            <div className="mt-3" style={{textAlign: 'center', display: 'flex', justifyContent: 'center'}}>
                                <div id="qrCodeBox">
                                    <QRCode value={referenceNumber} size="100" />
                                    <div>{referenceNumber}</div>
                                </div>
                            </div>
                        }
                    </fieldset>

                    <fieldset className="mt-3">
                        <legend>Document Information</legend>
                        <div className="doc-info">
                            <div className="doc-info-div">
                                <div className="doc-info-div-row">
                                    <div className="doc-info-labels width-100px pr-2">
                                        <span>* Title:</span>
                                    </div>
                                    <div className="flex-grow-1 pr-3">
                                        <input type="text" value={title} onChange={ (e) => setTitle(e.target.value) } />
                                    </div>
                                </div>
                                <div className="doc-info-div-row mt-2">
                                    <div className="doc-info-labels width-100px pr-2">
                                        <span>* Description:</span>
                                    </div>
                                    <div className="flex-grow-1 pr-3">
                                        <textarea name="" id="" rows="6" value={description} onChange={ (e) => setDescription(e.target.value) }></textarea>
                                    </div>
                                </div>
                                <div className="doc-info-div-row mt-2">
                                    <div className="doc-info-labels width-100px pr-2">
                                        <span>* Notes:</span>
                                    </div>
                                    <div className="flex-grow-1 pr-3">
                                        <textarea name="" id="" rows="6" value={notes} onChange={ (e) => setNotes(e.target.value) }></textarea>
                                    </div>
                                </div>
                            </div>

                            <div className="doc-info-div">
                                <div className="doc-info-div-row">
                                    <div className="doc-info-labels pr-2">
                                        <span>* Origin:</span>
                                    </div>
                                    <div className="flex-grow-1 pr-3">
                                        <select value={origin} onChange={ (e) => setOrigin(e.target.value) }>
                                            <option value="I">Internal</option>
                                            <option value="E">External</option>
                                        </select>
                                    </div>
                                </div>

                                { origin === "I" &&
                                    <>
                                        <div className="doc-info-div-row mt-2">
                                            <div className="doc-info-labels pr-2">
                                                <span>* Office:</span>
                                            </div>
                                            <div className="flex-grow-1 pr-3">
                                                <select value={departmentCode} onChange={ (e) => handleDepartmentChange(e.target.value) }>
                                                    <option value=""></option>
                                                    { departments.length > 0 && departments.map( (d, i) => (
                                                        <option key={i} value={d.dept_code}>{d.dept_title}</option>
                                                    ) ) }
                                                </select>
                                            </div>
                                        </div>

                                        <div className="doc-info-div-row mt-2">
                                            <div className="doc-info-labels pr-2">
                                                <span>* Name:</span>
                                            </div>
                                            <div className="flex-grow-1 pr-3">
                                                <select value={originUserID} onChange={ (e) => setOriginUserID(e.target.value) }>
                                                    <option value=""></option>
                                                    { names.length > 0 && names.map( (n, i) => (
                                                        <option key={i} value={n.userid}>{n.name}</option>
                                                    ) ) }
                                                </select>
                                            </div>
                                        </div>
                                    </>
                                }

                                { origin === "E" &&
                                    <>
                                        <div className="doc-info-div-row mt-2">
                                            <div className="doc-info-labels pr-2">
                                                <span>* First Name:</span>
                                            </div>
                                            <div className="flex-grow-1 pr-3">
                                                <input type="text" value={fname} onChange={ (e) => setFname(e.target.value) } />
                                            </div>
                                        </div>

                                        <div className="doc-info-div-row mt-2">
                                            <div className="doc-info-labels pr-2">
                                                <span>* Last Name:</span>
                                            </div>
                                            <div className="flex-grow-1 pr-3">
                                                <input type="text" value={lname} onChange={ (e) => setLname(e.target.value) } />
                                            </div>
                                        </div>

                                        <div className="doc-info-div-row mt-2">
                                            <div className="doc-info-labels pr-2">
                                                <span>Position:</span>
                                            </div>
                                            <div className="flex-grow-1 pr-3">
                                                <input type="text" value={position} onChange={ (e) => setPosition(e.target.value) } />
                                            </div>
                                        </div>

                                        <div className="doc-info-div-row mt-2">
                                            <div className="doc-info-labels pr-2">
                                                <span>Office Name:</span>
                                            </div>
                                            <div className="flex-grow-1 pr-3">
                                                <input type="text" value={officeName} onChange={ (e) => setOfficeName(e.target.value) } />
                                            </div>
                                        </div>

                                        <div className="doc-info-div-row mt-2">
                                            <div className="doc-info-labels pr-2">
                                                <span>* Address:</span>
                                            </div>
                                            <div className="flex-grow-1 pr-3">
                                                <input type="text" value={address} onChange={ (e) => setAddress(e.target.value) } />
                                            </div>
                                        </div>

                                        <div className="doc-info-div-row mt-2">
                                            <div className="doc-info-labels pr-2">
                                                <span>* Country:</span>
                                            </div>
                                            <div className="flex-grow-1 pr-3">
                                                <select value={country} onChange={ (e) => setCountry(e.target.value) }>
                                                    <option value="PH">Philippines</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="doc-info-div-row mt-2">
                                            <div className="doc-info-labels pr-2">
                                                <span>* Contact Number:</span>
                                            </div>
                                            <div className="flex-grow-1 pr-3">
                                                <input type="text" value={contactNumber} onChange={ (e) => setContactNumber(e.target.value) } />
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </fieldset>

                    <div className="mt-2">
                        <button onClick={handlePrintQRcode} className="ml-2" disabled={ referenceNumber === "" }>Print QR Code</button>
                        <button onClick={ () => setShowModal(true) } className="ml-2">Send/Forward</button>
                    </div>
                </div>
            </FolderLayout>

            <Footer />

            { modal() }

            { modalSuccess() }
        </div>
    );
}

export default MainDocument;