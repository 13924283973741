import { useState } from "react";
import isStrongPassword from 'validator/lib/isStrongPassword';

import FolderLayout from "../components/FolderLayout";
import Footer from "../components/Footer";
import Header from "../components/Header";

import iconError from '../images/icons/error.png';
import iconSuccess from '../images/icons/success.png';

const ChangePassword = ({ properties: p }) => {
    const MINIMUM_LENGTH = 8;

    const [ currentPassword, setCurrentPassword ] = useState('');
    const [ newPassword, setNewPassword ] = useState('');
    const [ confirmNewPassword, setConfirmNewPassword ] = useState('');

    const [ errors, setErrors ] = useState([]);
    const [ successResult, setSuccessResult ] = useState(null);
    const [ isLoading, setIsLoading ] = useState(false);

    const clear = () => {
        setCurrentPassword('');
        setNewPassword('');
        setConfirmNewPassword('');

        setErrors([]);
        setSuccessResult(null);
    }

    const handleSubmit = () => {
        const arrErrors = [];

        if( !currentPassword || !newPassword || !confirmNewPassword ) return setErrors([{ err_msg: 'All fields are required.' }]); //exit

        if( newPassword.length < MINIMUM_LENGTH ) arrErrors.push({ err_msg: `New password must be at least ${MINIMUM_LENGTH} characters in length.` });
        if( newPassword !== confirmNewPassword ) arrErrors.push({ err_msg: 'New and confirm passwords do not match.' });
        if( !isStrongPassword(newPassword) ) arrErrors.push({ err_msg: 'New password is weak.' });

        setErrors( arrErrors );

        if( arrErrors.length > 0 ) return; //exit

        setIsLoading(true);
        const userDetails = { currentPassword, newPassword, confirmNewPassword };

        fetch('/api/users/change-password',
            {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(userDetails)
            }).then( res => res.json() )
            .then( res => {
                if( Array.isArray(res) ) { //returned an array with error message
                    setErrors( res );
                    setSuccessResult(null);
                } else { //no error, changed password successfully
                    setSuccessResult(res);
                    setErrors([]);

                    setCurrentPassword('');
                    setNewPassword('');
                    setConfirmNewPassword('');
                }

                setIsLoading(false);
            })
        ;
    }

    return (
        <div className="container">
            <Header name={p.name} department={p.department} />

            <FolderLayout page="change-password">
                <>
                    <div className="change-password">
                        <div className="mt-4 cp-box">
                            <label for="curpass">* Current Password</label>
                            <input type="password" id="curpass" value={currentPassword} onChange={ (e) => setCurrentPassword(e.target.value) } required />
                        </div>

                        <div className="mt-2 cp-box">
                            <label for="newpass">* New Password</label>
                            <input type="password" id="newpass" value={newPassword} onChange={ (e) => setNewPassword(e.target.value) } required />
                        </div>

                        <div className="mt-2 cp-box">
                            <label for="connewpass">* Confirm New Password</label>
                            <input type="password" id="connewpass" value={confirmNewPassword} onChange={ (e) => setConfirmNewPassword(e.target.value) } required />
                        </div>

                        <div className="mt-2 cp-box">
                            <label></label>
                            <span>Your new password must be at least {MINIMUM_LENGTH} characters in length with at least one lowercase, uppercase, number, and special character.</span>
                        </div>

                        
                        { errors.length > 0 &&
                            <div className="cp-box">
                                <label></label>
                                <div className="error div" style={{marginTop: '0.25rem'}}>
                                    <div className="mb-2">
                                        <img src={iconError} alt="Error" />
                                        <h3 className="ml-2">Error</h3>
                                    </div>
                                    { errors.map( (e) => (
                                        <div key={e.err_msg} className="mt-2">{e.err_msg}</div>
                                    ) ) }
                                </div>
                            </div>
                            
                        }

                        { successResult &&
                            <div className="cp-box">
                                <label></label>
                                <div className="success div" style={{marginTop: '0.25rem'}}>
                                    <img src={iconSuccess} alt="Success" />
                                    <h3 className="ml-2">{successResult.msg}</h3>
                                </div>
                            </div>
                        }

                        <div className="mt-2 mb-3 cp-box">
                            <label>&nbsp;</label>
                            <div className="div">
                                <button onClick={handleSubmit} className="mr-2">Save</button>
                                <button onClick={clear}>Clear</button>
                            </div>
                        </div>
                    </div>
                </>
            </FolderLayout>

            <Footer />
        </div>
    );
}
 
export default ChangePassword;