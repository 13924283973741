
const DocDetailsForAdmin = ({ docDetails }) => {
    if( docDetails.effstatus === "W" ) docDetails.effstatus = "Warning";
    else if( docDetails.effstatus === "FM" ) docDetails.effstatus = "For Memo";
    else if( docDetails.effstatus === "PEM" ) docDetails.effstatus = "Printed/E-mailed Memo";

    return (
        <fieldset className="mt-3">
            <legend>Document Details</legend>

            <div style={s.container}>
                <table style={s.table}>
                    <tr style={s.tr}>
                        <td style={s.tdLeft}>Document ID:</td>
                        <td>{docDetails.doc_id}</td>
                    </tr>
                    <tr style={s.tr}>
                        <td style={s.tdLeft}>Document Title:</td>
                        <td>{docDetails.title}</td>
                    </tr>
                    <tr style={s.tr}>
                        <td style={s.tdLeft}>Document Description:</td>
                        <td>{docDetails.descr}</td>
                    </tr>
                    <tr style={s.tr}>
                        <td style={s.tdLeft}>Remarks:</td>
                        <td>{docDetails.remarks}</td>
                    </tr>
                    <tr style={s.tr}>
                        <td style={s.tdLeft}>Department:</td>
                        <td>{docDetails.dept_title}</td>
                    </tr>
                    <tr style={s.tr}>
                        <td style={s.tdLeft}>Status:</td>
                        <td>{docDetails.effstatus}</td>
                    </tr>
                    <tr style={{...s.tr, borderBottom: 'none'}}>
                        <td style={s.tdLeft}>Warning/For Memo Date:</td>
                        <td>{docDetails.effstatus_dt}</td>
                    </tr>
                </table>
            </div>
        </fieldset>
    );
}

const s = { //styles
    container: {
        backgroundColor: 'rgb(234 248 255)',
        width: '100%',
        padding: '5px 10px',
        border: '1px solid #bbb',
        borderRadius: '10px'
    },
    table: {
        width: '100%',
    },
    tr: {
        borderBottom: '1px solid #bbb',
        lineHeight: '2rem'
    },
    tdLeft: {
        textAlign: 'right',
        paddingRight: '6px',
        width: '25%',
        fontWeight: 'bold'
    }
}

export default DocDetailsForAdmin;