import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';

import iconClose from "../images/icons/close.png";
import iconSuccess from '../images/icons/success.png';

const DocumentAttachments = ({ attachments, ids, getDocumentDetails }) => {
    const [ showAddAttachmentModal, setShowAddAttachmentModal ] = useState(false);
    const [ showModalSuccess, setShowModalSuccess ] = useState("");
    const [ attachmentsList, setAttachmentsList ] = useState([]);
    const [ selectedAttachment, setSelectedAttachment ] = useState("");
    const [ selectedAttachmentTitle, setSelectedAttachmentTitle ] = useState("");
    const [ refNumber, setRefNumber ] = useState("");
    const [ description, setDescription ] = useState("");

    useEffect( () => {
        fetch(`/api/dts-action/get-attachments-list/${ids.doc_type_id}`)
            .then( res => res.json() )
            .then( res => {
                if( 'attachments' in res ) { //attachments list is available
                    setAttachmentsList(res.attachments);

                    setSelectedAttachment(res.attachments[0].attach_type_id); //set default
                    setSelectedAttachmentTitle(res.attachments[0].title);
                }
            })
        ;
    }, [] );

    const addAttachment = () => {
        fetch('/api/dts-action/add-attachment',
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ doc_id: ids.doc_id, attach_type_id: selectedAttachment, selectedAttachmentTitle, refNumber, description })
            }).then( res => res.json() )
            .then( res => {
                if( 'successfulInsert' in res && res.successfulInsert ) { //action list is available
                    getDocumentDetails(ids.doc_id);
                    setShowModalSuccess("You have successfully added an attachment.");

                    //clear and hide add action modal
                    setSelectedAttachment(attachmentsList[0].attach_type_id); //set default
                    setSelectedAttachmentTitle(attachmentsList[0].title)
                    setDescription("");
                    setRefNumber("");
                    setShowAddAttachmentModal(false);
                }
            })
        ;
    }

    const setAttachmentSelected = (value, text) => {
        setSelectedAttachment(value);
        setSelectedAttachmentTitle(text);
    }

    const modalAddAttachment = () => {
        return (
            <Modal show={showAddAttachmentModal} onHide={ () => setShowAddAttachmentModal(false) } backdrop="static" keyboard={false} centered>
                <Modal.Body>
                    <div className="text-align-right">
                        <input type="image" src={iconClose} onClick={ () => setShowAddAttachmentModal(false) } />
                    </div>

                    <div className="d-flex justify-content-end align-items-center mt-3">
                        <label className="text-align-right">Add Attachment:</label>
                        <select className="width-70 ml-2" value={selectedAttachment} onChange={ (e) => setAttachmentSelected(e.target.value, e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text) }>
                            { attachmentsList.length > 0 && attachmentsList.map( (al, i) => (
                                <option key={i} value={al.attach_type_id}>{al.title}</option>
                            ) ) }
                        </select>
                    </div>

                    <div className="d-flex justify-content-end align-items-center mt-3">
                        <label className="text-align-right">Reference Number:</label>
                        <input type="text" className="width-70 ml-2" value={refNumber} onChange={ (e) => setRefNumber(e.target.value) } />
                    </div>

                    <div className="d-flex justify-content-end align-items-center mt-3">
                        <label className="text-align-right">Description:</label>
                        <textarea rows="4" className="width-70 ml-2" value={description} onChange={ (e) => setDescription(e.target.value) }></textarea>
                    </div>

                    <div className="d-flex justify-content-end align-items-center mt-3 mb-4">
                        <label></label>
                        <div className="width-70 ml-2">
                            <button onClick={addAttachment}>Add Attachment</button>
                            <button className="ml-2" onClick={ () => setShowAddAttachmentModal(false) }>Cancel</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    };

    const modalSuccess = () => {
        return (
            <Modal show={showModalSuccess} onHide={ () => setShowModalSuccess("") } backdrop="static" keyboard={false} centered>
                <Modal.Body>
                    <div className="text-align-right">
                        <input type="image" src={iconClose} onClick={ () => setShowModalSuccess("") } />
                    </div>
                    <div className="mt-4 mb-3">
                        <div className="mb-2 text-align-center">
                            <img src={iconSuccess} alt="Success" />
                            <span className="ml-2 bold" style={{ color: 'rgb(0, 72, 255)', fontSize: '1.2rem' }}>{showModalSuccess}</span>
                        </div>
                    </div>
                    <div className="mb-3 text-align-center">
                        <button className="ml-2" onClick={ () => setShowModalSuccess("") }>Okay</button>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }

    return (
        <fieldset className="mt-3">
            <legend>Attachments/Supporting Documents</legend>

            <div className="text-align-right">
                <Link to="#" className="bold mr-2" onClick={ () => setShowAddAttachmentModal(true) }>Add Attachment/Supporting Doc.</Link>
            </div>

            { attachments.length === 0 &&
                <div className="mt-2 text-align-center">
                    <i>No available attachments.</i>
                </div>
            }

            { attachments.length > 0 &&
                <div className="mt-2" style={s.container}>
                    <table style={s.table}>
                        <tr style={s.tr}>
                            <th style={{ width: '20%' }}>Attachment Ref. #</th>
                            <th style={{ width: '50%' }}>Attachment Type</th>
                            <th style={{ width: '30%' }}>Date Added</th>
                        </tr>
                        { attachments.map( (a, i) => (
                            <tr key={i} style={ attachments.length === i + 1 ? {...s.tr, borderBottom: 'none'} : s.tr }>
                                <td>{a.ref_num}</td>
                                <td>{a.title}</td>
                                <td>{a.added_dt}</td>
                            </tr>
                        )) }
                    </table>
                </div>
            }

            { modalAddAttachment() }

            { modalSuccess() }
        </fieldset>
    );
}

const s = { //styles
    container: {
        backgroundColor: 'rgb(234 248 255)',
        width: '100%',
        padding: '5px 10px',
        border: '1px solid #bbb',
        borderRadius: '10px'
    },
    table: {
        width: '100%',
    },
    tr: {
        borderBottom: '1px solid #bbb',
        lineHeight: '2rem'
    }
}

export default DocumentAttachments;