import { useState } from "react";

import ControlsForAdmin from "../components/ControlsForAdmin";
import DocDetailsForAdmin from "../components/DocDetailsForAdmin";
import FolderLayout from "../components/FolderLayout";
import Footer from "../components/Footer";
import Header from "../components/Header";
import SentMemos from "../components/SentMemos";
import WarningsAndForMemos from "../components/WarningsAndForMemos";

import iconMemoRed from '../images/icons/memoRed.png';
import iconPemOrange from '../images/icons/pemOrange.png';
import iconWarnYellow from '../images/icons/warnYellow.png';

const Admin = ({ properties: p }) => {
    const [ docDetails, setDocDetails ] = useState(null);
    const [ warningID, setWarningID ] = useState("");
    const [ reFetch, setReFetch ] = useState(1);

    return (
        <div className="container">
            <Header name={p.name} department={p.department} />

            <FolderLayout page="admin">
                { !docDetails &&
                    <fieldset>
                        <legend>Status Legend</legend>

                        <div className="d-flex justify-content-evenly">
                            <div>
                                <img src={iconWarnYellow} alt="Warning" />
                                <span className="ml-2">- Warning</span>
                            </div>
                            <div>
                                <img src={iconMemoRed} alt="For Memo" />
                                <span className="ml-2">- For Memo</span>
                            </div>
                            <div>
                                <img src={iconPemOrange} alt="Printed/E-mailed Memo" />
                                <span className="ml-2">- Printed/E-mailed Memo</span>
                            </div>
                        </div>
                    </fieldset>
                }
                
                { !docDetails && <WarningsAndForMemos statusIcons={{iconMemoRed, iconPemOrange, iconWarnYellow}} setDocDetails={setDocDetails} setWarningID={setWarningID} reFetch={reFetch} /> }

                { docDetails && <DocDetailsForAdmin docDetails={docDetails}/> }

                { docDetails && <SentMemos warningID={warningID} effstatus={docDetails.effstatus} /> }

                { docDetails && <ControlsForAdmin docDetails={docDetails} setDocDetails={setDocDetails} warningID={warningID} setReFetch={setReFetch} /> }
            </FolderLayout>

            <Footer />
        </div>
    );
}
 
export default Admin;