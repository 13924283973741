import { Link } from "react-router-dom";

const SearchDocInDepartmentResults = ({ docDetails, didSearch, setShowDocDetails, advSearchResult, setDocDetails, setTransHistory }) => {

    const handleShowDetails = (docID) => {
        fetch(`/api/dts-work-history/basic-search/${docID}`)
            .then( res => res.json() )
            .then( res => {
                if( res.docDetails ) setDocDetails(res.docDetails);
                if( res.transHistory ) setTransHistory(res.transHistory);

                setShowDocDetails(true);
            })
        ;
    }
    
    return (
        <fieldset className="mt-3">
            <legend>Search Document Results</legend>

            { docDetails && didSearch &&
                <div style={s.container}>
                    <table style={s.table}>
                        <tr style={s.tr}>
                            <th style={{ width: '20%' }}>Document ID</th>
                            <th style={{ width: '55%' }}>Title</th>
                            <th style={{ width: '25%' }}>Requesting Office</th>
                        </tr>
                        <tr style={ {...s.tr, borderBottom: 'none'} }>
                            <td>{ docDetails.doc_id }</td>
                            <td>
                                <Link to="#" onClick={ () => setShowDocDetails(true) }>{ docDetails.title }</Link>
                            </td>
                            <td>{ docDetails.office }</td>
                        </tr>
                    </table>
                </div>
            }

            { !docDetails && didSearch &&
                <div className="text-align-center bold">
                    <em>Document not found.</em>
                </div>
            }

            { advSearchResult.length > 0 &&
                <div style={s.container}>
                    <table style={s.table}>
                        <tr style={s.tr}>
                            <th style={{ width: '20%' }}>Document ID</th>
                            <th style={{ width: '55%' }}>Title</th>
                            <th style={{ width: '25%' }}>Requesting Office</th>
                        </tr>
                        { advSearchResult.map( (asr, i) => (
                            <tr key={i} style={ advSearchResult.length === i + 1 ? {...s.tr, borderBottom: 'none'} : s.tr }>
                                <td>{asr.doc_id}</td>
                                <td>
                                    <Link to="#" onClick={ () => handleShowDetails(asr.doc_id) }>{asr.title}</Link>
                                </td>
                                <td>{asr.office}</td>
                            </tr>
                        )) }
                    </table>
                </div>
            }

        </fieldset>
    );
}

const s = { //styles
    container: {
        backgroundColor: 'rgb(234 248 255)',
        width: '100%',
        padding: '5px 10px',
        border: '1px solid #bbb',
        borderRadius: '10px'
    },
    table: {
        width: '100%',
    },
    tr: {
        borderBottom: '1px solid #bbb',
        lineHeight: '2rem'
    }
}
 
export default SearchDocInDepartmentResults;