import bar from '../images/bar.gif';

const Footer = () => {
    return (
        <div className="footer">
            <div className="footer-bar" style={styles.footerBar}>
                <span>Copyright &copy; {(new Date().getFullYear())} Trivut Corp.</span>
            </div>
        </div>
    );
}

const styles = {
    footerBar: {
        background: `url(${bar}) repeat-x`
    }
}

export default Footer;