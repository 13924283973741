import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

import SendEmail from './SendEmail';
import SendMemo from './SendMemo';

import iconClose from "../images/icons/close.png";

const ControlsForAdmin = ({ docDetails, setDocDetails, warningID, setReFetch }) => {
    const [ showModal, setShowModal ] = useState(false);
    const [ extendDays, setExtendDays ] = useState("1");

    const modalIgnoreAndExtend = () => {
        return (
            <Modal show={showModal} onHide={ () => setShowModal(false) } backdrop="static" keyboard={false} centered>
                <Modal.Body>
                    <div className="text-align-right">
                        <input type="image" src={iconClose} onClick={ () => setShowModal(false) } />
                    </div>
                    <fieldset className="mt-2" style={s.fieldset}>
                        <legend style={s.legend}>Ignore and Extend</legend>
                        
                        <div className="bold mb-2">Enter the number of days of extension:</div>

                        <select value={extendDays} onChange={ (e) => setExtendDays(e.target.value) } style={{width: 'auto'}}>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                            <option value="13">13</option>
                            <option value="14">14</option>
                            <option value="15">15</option>
                            <option value="16">16</option>
                            <option value="17">17</option>
                            <option value="18">18</option>
                            <option value="19">19</option>
                            <option value="20">20</option>
                            <option value="21">21</option>
                            <option value="22">22</option>
                            <option value="23">23</option>
                            <option value="24">24</option>
                            <option value="25">25</option>
                            <option value="26">26</option>
                            <option value="27">27</option>
                            <option value="28">28</option>
                            <option value="29">29</option>
                            <option value="30">30</option>
                        </select>
                    </fieldset>

                    <div className="mt-3 mb-3 text-align-center">
                        <button onClick={ handleIgnoreExtend } className="ml-2">Extend</button>
                        <button className="ml-2" onClick={ () => setShowModal(false) }>Cancel/Close</button>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }

    const handleIgnoreExtend = () => {
        if( !window.confirm("Are you sure you want to extend?") ) return; //exit

        fetch(`/api/dts-admin/ignore-and-extend/${warningID}`,
            {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ extendDays })
            }).then( res => res.json() )
            .then( res => {
                if( 'success' in res && res.success ) {
                    setReFetch( i => i + 1 );
                    setShowModal(false);
                    setDocDetails(null);
                } else alert("Something went wrong!");
            })
        ;
    }

    return (
        <fieldset className="mt-3">
            <legend>Controls</legend>

            <div className="text-align-center">
                <button className="ml-2" onClick={ () => setDocDetails(null) }>Back</button>
                <button className="ml-2" onClick={ () => setShowModal(true) } disabled={ docDetails.effstatus === "Printed/E-mailed Memo" ? true : false }>Ignore & Extend</button>
                <SendMemo effstatus={docDetails.effstatus} warningID={warningID} setReFetch={setReFetch} setDocDetails={setDocDetails} />
                <SendEmail warningID={warningID} />
            </div>

            { modalIgnoreAndExtend() }
        </fieldset>
    );
}

const s = {
    fieldset: {
        border: "1px solid rgb(201 201 201)",
        borderRadius: "8px",
        padding: "12px"
    },
    legend: {
        textAlign: "right",
        border: "1px solid rgb(201 201 201)",
        borderRadius: "2px",
        padding: "2px 4px"
    }
};

export default ControlsForAdmin;