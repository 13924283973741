import { useState, useEffect } from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import iconHelp from '../images/icons/question.png';
import receiving from '../images/icons/receiving.png';
import refresh from '../images/icons/refresh.png';

import '../css/dts.css';

const Help = ({page}) => {
    const [show, setShow] = useState(false);
    const [showDefaultHelp, setDefaultHelp] = useState(true);
    const [showAltHelp, setAltHelp] = useState(false);
    const [showLoginHelp, setShowLoginHelp] = useState(false);
    const [showChangePassHelp, setShowChangePassHelp] = useState(false);
    const [showForgotPassHelp, setShowForgotPassHelp] = useState(false);
    const [showNewUserHelp, setShowNewUserHelp] = useState(false);
    const [showEditProfileHelp, setShowEditProfileHelp] = useState(false);
    const [showMainDocHelp, setShowMainDocHelp] = useState(false);
    const [showReceivingHelp, setShowReceivingHelp] = useState(false);
    const [showActionHelp, setShowActionHelp] = useState(false);
    const [ShowDocTrackHelp, setShowDocTrackHelp] = useState(false);
    const [showWorkHistHelp, setShowWorkHistHelp] = useState(false);
    const [showAdminHelp, setShowAdminHelp] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        if (page === "login"){
            setDefaultHelp(false);
            setAltHelp(true);
            setShowLoginHelp(true);
        } else if (page === "change-password") {
            setShowChangePassHelp(true);
        } else if (page === "forgot-password") {
            setDefaultHelp(false);
            setAltHelp(true);
            setShowForgotPassHelp(true);
        } else if (page === "new-user") {
            setShowNewUserHelp(true);
        } else if (page === "edit-profile") {
            setShowEditProfileHelp(true);
        } else if (page === "main-doc") {
            setShowMainDocHelp(true);
        } else if (page === "receiving") {
            setShowReceivingHelp(true);
        } else if (page === "action") {
            setShowActionHelp(true);
        } else if (page === "doc-track") {
            setShowDocTrackHelp(true);
        } else if (page === "work-history") {
            setShowWorkHistHelp(true);
        } else if (page === "admin") {
            setShowAdminHelp(true);
        }
    }, [] );


    return (
        <>
            {/* <a href="#" className="help text-light" onClick={handleShow}> */}
                {
                    showAltHelp?
                        <>
                            <a href="#" className="help link1" onClick={handleShow}>
                                Help <img className="help-icon mr-1 text-light" style={{ height: '20px', color: '#99cdff' }} src={iconHelp} alt="Help" />
                            </a>   
                        </>
                    :null
                }
                {
                    showDefaultHelp?
                        <>
                            <a href="#" className="help" onClick={handleShow}>
                                Help <img className="help-icon mr-1" src={iconHelp} alt="Help" />
                            </a> 
                        </>
                    :null
                }   
            {/* </a> */}

            <Modal 
                show={show} 
                onHide={handleClose}
                size="md"
                centered
                scrollable
            >
            <Modal.Header className="pb-4" closeButton>
            <Modal.Title>
                {
                    showLoginHelp?
                        <>Login </>
                    :null
                }
                {
                    showChangePassHelp?
                        <>Change Password </>
                    :null
                }
                {
                    showForgotPassHelp?
                        <>Forgot Password </>
                    :null
                }
                {
                    showNewUserHelp?
                        <>Add New User </>
                    :null
                }
                {
                    showEditProfileHelp?
                        <>Edit Profile </>
                    :null
                }
                {
                    showMainDocHelp?
                        <>Main Document </>
                    :null
                }
                {
                    showReceivingHelp?
                        <>Document Receiving </>
                    :null
                }
                {
                    showActionHelp?
                        <>Document Action </>
                    :null
                }
                {
                    ShowDocTrackHelp?
                        <>Document Tracking </>
                    :null
                }
                {
                    showWorkHistHelp?
                        <>History </>
                    :null
                }
                {
                    showAdminHelp?
                        <>Admin </>
                    :null
                }
                Help
            </Modal.Title>
            </Modal.Header>
            <Modal.Body className="help-modal mb-5">
                <p>&nbsp;</p>
                {
                    showLoginHelp?
                        <>
                            <strong>Steps:</strong><br />
                            1. Enter your username and password provided by the System Administrator.<br /><br />
                            2. Click <em>Login</em> button to enter the system.<br /><br />
                            3. If error message appears, please check your username and password.You might misspelled either of the two entries.<br /><br />
                            <b>Note:</b> Password is key-sensitive, which further means that the password you have provided should be exactly the same as those you enrolled or registered.
                        </>
                    :null
                }
                {
                    showChangePassHelp?
                        <>
                            <strong>Steps:</strong><br /><br />
                            1. Fill-out the three textboxes. Make sure to provide your current password, new password and finally confirmation of the new password.<br /><br />
                            2. Click <em>Save</em> button to save your newly created password.<br /><br />
                            3. Try your newly created password by clicking the link <em>'Sign Out'</em> at the top left of the page (opposite your Name). You will then be directed to the main or home page, log-in to your account and use your new password.
                        </>
                    :null
                }
                {
                    showForgotPassHelp?
                        <>
                            <strong>Steps:</strong><br /><br />
                            1. Enter your username.<br /><br />
                            2. By clicking the <em>Send Email</em> button a confirmation box will appear to confirm your action. Clicking the <em>Cancel</em> button will return to the Login page.<br /><br />
                            3. Click <em>Ok</em> button to continue and a successful message will appear notifying your request was successfully sent to your e-mail address.
                        </>
                    :null
                }
                {
                    showNewUserHelp?
                        <>
                            <strong>Steps:</strong><br />
                            1. Enter required information of the user (Last Name, First Name, Middle Name, Gender, Department, Role, Email address).<br /><br />
                            <strong>Note: </strong>Asterisk (*) means field is required.<br /><br />
                            2. After selecting a department for the user, check the Department Head if the user is assigned as a department head. If the department has already a department head assigned, the system will disable the check box. Only one department head is allowed per department. <em>(This will be used for following up or sending email to department heads whenever there is a warning on some documents at hand)</em>.<br /><br />
                            <strong>Note:</strong> If role assigned to the user is Messenger, textbox Email will be disabled. For security purposes, Messengers are only enrolled to appear in a list of messengers to be selected by the sender whose task is to officially bring the physical documents. No log-in account is generated in this role.<br /><br />
                            3. Click <em>Save</em> button to save user in the database. Auto-generated log-in account (username and password) is displayed after saving.<br /><br />
                            4. Click Clear button to refresh the page for more new entries or enrollees.
                        </>
                    :null
                }
                {
                    showEditProfileHelp?
                        <>
                            <strong>Steps:</strong><br /><br />
                            1. Click <strong>Edit User Profile</strong> menu from the tabs.<br /><br />
                            2. In the search panel, type the name, department or role you want to search.<br /><br />
                            3. Click <em>Search</em> button to display the results. Leaving the textbox blank will display all users in the system.<br /><br />
                            4. Select the name from the results to update his/her profile.<br /><br />
                            5. Click <em>Update</em> button to save the changes.
                        </>
                    :null
                }
                {
                    showMainDocHelp?
                        <>
                            <strong>Steps:</strong><br /><br />
                            1. Select Document Type from the dropdown list to generate the Document ID (Reference Number).<br /><br />
                            2. Fill-out form. All fields with asterisks (*) are required.<br /><br />
                            3. Select type of origin from dropdown list. If the document emanates within your office, select 'Internal' from the dropdown list and a list of offices and personnel under that office/department will be displayed. If the document comes from outside entities, select then  'External' from the dropdown list and fill out with data all required fields. <br /><br />
                            4. Printing the Document ID (Reference Number) can be done after selecting the Document Type or after filling-out the form.<br /><br />
                            5. By clicking the <em>Print</em> button, the system will display a preview on the barcode through a small new window and you can edit the settings of the printer. Click <em>Print</em> to print the Document ID (Reference number).<br /><br />
                            6. After the entering the documents information, click the <em>Send/Forward</em> button to forward the document to another station for the next transaction. <br /><br />
                            7. By clicking the <em>Send/Forward</em> button, the system will display a form prompting the user where to send the document for next action, select a messenger from the list who will officially bring the physical document and add remarks for additional instructions.<br /><br />
                            8.  Click <em>Send</em> button to forward the document to the next station and data is saved in the database.<br /><br />
                            9. Once sent, a successful message will appear notifying you that the document had been successfully sent.  The document can now be viewed in the destination as incoming document even before the physical document arrives.<br /><br />
                            10. Click <em>Ok</em> button to refresh the page for new entry/ies.
                        </>
                    :null
                }
                {
                    showReceivingHelp?
                        <>
                            <strong>Steps:</strong><br /><br />
                            1. Two ways in receiving a document.<br />
                                <ul>
                                    <li>A. Barcode scanning – Scan the barcode label or sticker pasted in the document. The document scanned is automatically received and automatically goes into the active document list. </li>
                                    <li>B. Manually received a document – compare the document at hand versus the document in the system. Select the document to be received. Another option is to select the 'Select All' checkbox for batch receiving.</li>
                                    <li> B.1. Click the <em>Receive</em> button to receive the document and this stores the receiver data and timestamp into the database.</li>
                                </ul>
                            2. Clicking the link 'Details' in the list of document will display the details of the document and the list of attachments.<br /><br />
                            3. The <em>Return</em> button is enabled for you to return or send back the document to the responsible department prior to receiving the document. This feature is used when you notice that said document lack attachments, unsigned (where signatures are necessary) and other observed inconsistencies.<br /><br />
                            4. Clicking the <em>Return</em> button will display a form that contains a destination where to send the document, a list of Messengers assigned to officially bring the physical document, name of the person to whom you informed that there is a document to be returned and the reason for its return or list of lacking documents.<br /><br />
                            5. A confirmation box will appear to confirm your action. Clicking the <em>OK</em>button will send the document to the destination specified. Successful message will appear at the bottom of the page. The returned document is then removed from the list. The receiver department is notified about the arrival of such document in the <img src={receiving} alt="receiving" /> For Receiving page.<br /><br />
                            6. <img src={refresh} alt="refresh" /> '<span style={{color: "#0000cc"}}>Refresh for New Incoming Documents</span>' link is made available at the top right corner of the page to display the new incoming documents after the page was put on standby.
                        </>
                    :null
                }
                {
                    showActionHelp?
                        <>
                            <strong>Steps:</strong><br /><br />
                            Once a document is electronically (and physically received) the document automatically goes into the active documents list of the department (for action list).<br /><br />
                            1. Clicking the link 'Details' under Document Details panel will allow you to add attachments/supporting documents and under Document Actions the panel will allow adding the latest status of the document.<br /><br />
                            2. Clicking the link 'Add Attachment/Supporting Doc' will display a form containing the list of Attachments/documents in the dropdown list, Reference Number of the attached document and remarks. If the attachment or document is not found in the list, you can select 'Others' to type the name of the attachment or document. A blank textbox is provided to enter the data. 'Back' link is provided to return the mode to a dropdown list.<br /><br />
                            3. Click <em>Add Attachment</em> button to save the attachments to the database.  Saved attachment is displayed under Document Details panel and the latest status is displayed under Document Actions panel.<br /><br />
                            4. The use of the buttons:<br />
                            <ul>
                                <li><em>Send/Forward</em> - when a Unit or Department indicates the completion of its action,  it virtually forwards to the next department that is expected to receive or act next, based on agreed business rules. Immediately after this, the department is expected to bring the physical document to the destination department. The latter is notified about the arrival of such document in the <img src={receiving} alt="receiving" /> For Receiving page.
                                    <ul>
                                        <li>By clicking the <em>Send/Forward</em> button, a form is displayed containing the list of destination of the document through a dropdown list, list of Messengers who will officially bring the physical document to the destination and remarks for additional instructions.</li>
                                        <li>Click <em>Send</em> button to forward the document. Successful message will appear to notify you that the document was successfully forwarded.</li>
                                    </ul>
                                </li>
                                <li><em>Archive Document</em> - At the end of the documents' life cycle, the end user may "close" the document to signify that no further action is required on the document. It is now ready for archiving which means that the document is now history but may later be retrieved by searching especially when such document is needed as reference material. Once closed, the document will no longer appear in any work list.
                                    <ul>
                                        <li>By clicking the <em>Archive Document</em> button, a form is displayed to enter remarks.</li>
                                        <li>Click <em>Archive</em> button to close the document. A confirmation box will prompt you to confirm your action.</li>
                                        <li>Click <em>Ok </em>button to continue. Successful message will appear to notify you that the document was successfully archived.</li>
                                    </ul>
                                </li>
                                <li><em>Mark as Lost</em> – If the physical document is unable to be found or recovered while processing, the user may have the option to mark the document as “lost” and the option to specify a reason why it was lost. Once marked as lost, the document will no longer appear in any work list.
                                    <ul>
                                        <li>By clicking the Mark as Lost button, a form is displayed to specify your reason or remarks.</li>
                                        <li>Click Mark as Lost button. A confirmation box will prompt you to confirm your action.</li>
                                        <li>Click Ok button to continue. Successful message will appear to notify you that the document was successfully marked as lost.</li>
                                    </ul>
                                </li>
                                <li><em>Return Document</em> – is a function to return back the document to the responsible department whenever you notice some lacking attachments, lack or absence of signatures, etc. on the document at hand.
                                    <ul>
                                        <li>By clicking the <em>Return Document</em> button, the system will display a form that contains a destination where to send the document, list of Messenger who will officially bring the physical document, name of the person to whom you informed that there is a document to be returned and the reason for its return or list of lacking documents.</li>
                                        <li>Click the <em>Return</em> button to return or send back the document. A confirmation box will prompt you to confirm your action.</li>
                                        <li>Click <em>Ok</em> button to continue. Successful message will appear to notify you that the document was successfully returned.</li>
                                    </ul>
                                </li>
                            </ul>
                        </>
                    :null
                }
                {
                    ShowDocTrackHelp?
                        <>
                            <strong>Steps:</strong><br /><br />
                            Two ways in searching a document.<br /><br />
                            1. Barcode scanning
                            <ul>
                                <li>Scan the barcode label or sticker pasted in the document. The document that matches the barcode will be displayed.</li>
                            </ul>2. Manual Search
                            <ul>
                                <li>Select Manual Search</li>
                                <li>For 'Basic Search': Enter Document ID. Click Search button to display the results.</li>
                                <li>Click the Title of the document in the list of results to display the document details and transaction history. Drill down is available in the Transaction History panel to display the action taken by the department.</li>
                                <li>For 'Advance Search': You have the option to search either the 3 options: Document Type, Date Received (From Date to Date), and Description. For accurate results, you can specify all the 3 queries. Click Search button to display results.</li>
                                <li>Paging is displayed if results are more than 10. Click page numbers to navigate to another page result. </li>
                            </ul>
                        </>
                    :null
                }
                {
                    showWorkHistHelp?
                        <>
                            <strong>Steps:</strong><br /><br />
                            <strong>Search documents</strong><br /><br />
                            1. <u>Basic Search</u>: Enter Document ID. Click <em>Search</em> button to display results.<br /><br />
                            2. Click the Title of the document to display the document details and actions taken.<br /><br />
                            3. <u>Advance Search</u>: You have the option to search either the 3 options: Document Type, Date Received (From Date to Date), and Description. For more accurate result, you can specify all the 3 queries. Click Search button to display results.<br /><br />
                            4. Paging is displayed if results are more than 10. Click page numbers to navigate to another page results.<br /><br />
                            <strong>Search attachments</strong><br /><br />
                            1. <u>Basic Search:</u> Select from the list of Attachments in the dropdown list. The number beside the name of attachment corresponds to the number attached.<br /><br />
                            2. Click <em>Search</em> button to display results.<br /><br />
                            3. <u>Advance Search</u>: You have the option to search either the 2 options: Attachments and Reference Number. For a more accurate result, you can specify both queries. Click <em>Search</em> button to display the results.<br /><br />
                            4. Clicking the Document ID from the list of results will display the document's details and actions taken.
                        </>
                    :null
                }
                {
                    showAdminHelp?
                        <>
                            <strong>Steps:</strong><br /><br/>
                            The <strong>Warning/Memos Page</strong> enumerates the list of overdue documents (through an automated and color-coded warning system).  The documents are categorized by Warning, For Memo and Printed/E-mailed memo.<br /><br />
                            1. By clicking the link 'Details' on the list of document, the system will display the details and the necessary actions to be taken (use buttons provided).<br /><br />
                            2. The use of the buttons:
                            <ul>
                                <li><em><strong>Back</strong></em> – return to the list of overdue documents.</li>
                                <li><em><strong>Ignore & Extend </strong></em>– system will display a form to enter the number of days of extension. By clicking the <em>Extend</em> button the system will extend the document's due and will no longer appear in the list.  The department concerned will be notified through change of the color-coded warning system. Once extended, the document will not appear in the list of overdue documents. </li>
                                <li><em><strong>Send Memo</strong></em> – is only enabled when the document reaches 'For Memo'.
                                    <ol>
                                        <li>By clicking <em>Send Memo</em> button a template is generated containing the displayed email address of the sender and recipient, both Subject and Message are editable.</li>
                                        <li>Click <em>Preview</em> button to display the memo before printing or e-mailing.</li>
                                        <li>Be guided of the uses of the following buttons after clicking the button 'Preview':
                                            <ul>
                                                <li><em><strong>Print</strong></em>
                                                    <ol>
                                                        <li>By clicking <em>Print</em> button, a new window along with the Print Dialog Box is opened to adjust the printer's setting. Click <em>Print</em> button to continue. Clicking <em>Cancel</em> button will close the opened window.</li>
                                                        <li>Click <em>Ok</em> button inside the message box to go back to the list of overdue documents.</li>
                                                        <li>The status of the document is now changed from 'For Memo' to 'Printed/E-mailed Memo'. </li>
                                                        <li>Copy of memo sent is displayed when you click the document's link 'Details' under Printed/E-mailed Memo status. </li>
                                                        <li>Clicking the Date and Time of memo sent or printed will display the content of the memo.</li>
                                                    </ol>
                                                </li>
                                                <li><em><strong>E-mail Memo</strong></em>
                                                    <ol>
                                                        <li>By clicking the <em>E-mail Memo</em> button, the system will automatically send the memo to the recipient.</li>
                                                        <li>A successful message will appear notifying that the memo was successfully sent. Otherwise an error message will appear. </li>
                                                        <li>Click <em>Ok</em> button inside the message box to go back to the list of overdue documents.</li>
                                                        <li>The status of the document is now changed from 'For Memo' to 'Printed/E-mailed Memo'. </li>
                                                        <li>Copy of memo sent is displayed when you click the document's link 'Details' under Printed/E-mailed Memo status.</li>
                                                        <li>Clicking the Date and Time of memo sent or printed will display the content of the memo. </li>
                                                    </ol>
                                                </li>
                                                <li><strong><em>Edit </em></strong>
                                                    <ol>
                                                        <li>By clicking <em>Edit</em> button, the previewed memo is closed and you will go back to the memo template to update changes. </li>
                                                    </ol>
                                                </li>
                                                <li><em><strong>Cancel/Close</strong></em>
                                                    <ol>
                                                        <li>By clicking <em>Cancel/Close</em> button, the previewed memo is closed and document's detail is displayed. </li>
                                                    </ol>
                                                </li>
                                            </ul>
                                        </li>
                                    </ol>
                                </li>
                                <li><em><strong>Send E-mail</strong></em> – the system will display a form containing email address of the sender and recipient. <em>(This is used to communicate or follow-up the concerned department with warned documents using this system feature)</em>.
                                    <ol>
                                        <li>By clicking the <em>Send</em> button your message is sent to the address specified in the recipient or 'To' section. </li>
                                        <li>A confirmation box will prompt you to confirm your action. </li>
                                        <li>Click <em>Ok</em> button to continue. A successful message will appear to notify that your message was successfully sent.</li>
                                        <li>Once the document had been forwarded to the other department and was received, the overdue document will not appear in the list. The same things happen when the document has been archived or marked as lost.</li>
                                    </ol>
                                </li>
                            </ul>
                        </>
                    :null
                }
                <p>&nbsp;</p>
            </Modal.Body>
            </Modal>
        </>
    );
}

export default Help;