import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Footer from '../components/Footer';
import FolderLayout from "../components/FolderLayout";
import Header from '../components/Header';

import Help from '../components/Help';

import '../css/dts-pages.css';

const ForgotPassword = () => {
    const [ username, setUsername ] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault(); //will prevent default page load

    }

    return (
        <div className="container">
            <Header />
                <div className="forgot-password page-content">
                    <div className="d-flex justify-content-center front-box my-4">
                        <fieldset className="box-content" style={{ maxWidth: "700px"}}>
                            {/* <legend>Forgot Password</legend> */}
                                <div className="help-link d-flex justify-content-end">
                                    <Help page={"forgot-password"} />
                                </div>
                                <div className="d-flex justify-content-center">
                                    <div className="p-2 fw-bolder">Enter your Username</div>
                                </div>
                                <div className="d-flex justify-content-center">
                                    <label className="mt-1" for="username">Username: </label>
                                    <input type="text" id="username" onChange={ (e) => setUsername(e.target.value) } required />
                                </div>
                                <div className="d-flex justify-content-center p-3">
                                    <Link to="/"><button>Cancel</button></Link>
                                    <button onClick={handleSubmit}>Send Email</button>
                                </div>
                        </fieldset>
                    </div> 
                </div>
            <Footer />
        </div>
    )
}

export default ForgotPassword;