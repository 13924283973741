import { useState, useEffect, useRef } from "react";

let numbersAudio = {
    'B1': require('../audio/b/B-1.mp3'),
    'B2': require('../audio/b/B-2.mp3'),
    'B3': require('../audio/b/B-3.mp3'),
    'B4': require('../audio/b/B-4.mp3'),
    'B5': require('../audio/b/B-5.mp3'),
    'B6': require('../audio/b/B-6.mp3'),
    'B7': require('../audio/b/B-7.mp3'),
    'B8': require('../audio/b/B-8.mp3'),
    'B9': require('../audio/b/B-9.mp3'),
    'B10': require('../audio/b/B-10.mp3'),
    'B11': require('../audio/b/B-11.mp3'),
    'B12': require('../audio/b/B-12.mp3'),
    'B13': require('../audio/b/B-13.mp3'),
    'B14': require('../audio/b/B-14.mp3'),
    'B15': require('../audio/b/B-15.mp3'),

    'I16': require('../audio/i/I-16.mp3'),
    'I17': require('../audio/i/I-17.mp3'),
    'I18': require('../audio/i/I-18.mp3'),
    'I19': require('../audio/i/I-19.mp3'),
    'I20': require('../audio/i/I-20.mp3'),
    'I21': require('../audio/i/I-21.mp3'),
    'I22': require('../audio/i/I-22.mp3'),
    'I23': require('../audio/i/I-23.mp3'),
    'I24': require('../audio/i/I-24.mp3'),
    'I25': require('../audio/i/I-25.mp3'),
    'I26': require('../audio/i/I-26.mp3'),
    'I27': require('../audio/i/I-27.mp3'),
    'I28': require('../audio/i/I-28.mp3'),
    'I29': require('../audio/i/I-29.mp3'),
    'I30': require('../audio/i/I-30.mp3'),

    'N31': require('../audio/n/N-31.mp3'),
    'N32': require('../audio/n/N-32.mp3'),
    'N33': require('../audio/n/N-33.mp3'),
    'N34': require('../audio/n/N-34.mp3'),
    'N35': require('../audio/n/N-35.mp3'),
    'N36': require('../audio/n/N-36.mp3'),
    'N37': require('../audio/n/N-37.mp3'),
    'N38': require('../audio/n/N-38.mp3'),
    'N39': require('../audio/n/N-39.mp3'),
    'N40': require('../audio/n/N-40.mp3'),
    'N41': require('../audio/n/N-41.mp3'),
    'N42': require('../audio/n/N-42.mp3'),
    'N43': require('../audio/n/N-43.mp3'),
    'N44': require('../audio/n/N-44.mp3'),
    'N45': require('../audio/n/N-45.mp3'),

    'G46': require('../audio/g/G-46.mp3'),
    'G47': require('../audio/g/G-47.mp3'),
    'G48': require('../audio/g/G-48.mp3'),
    'G49': require('../audio/g/G-49.mp3'),
    'G50': require('../audio/g/G-50.mp3'),
    'G51': require('../audio/g/G-51.mp3'),
    'G52': require('../audio/g/G-52.mp3'),
    'G53': require('../audio/g/G-53.mp3'),
    'G54': require('../audio/g/G-54.mp3'),
    'G55': require('../audio/g/G-55.mp3'),
    'G56': require('../audio/g/G-56.mp3'),
    'G57': require('../audio/g/G-57.mp3'),
    'G58': require('../audio/g/G-58.mp3'),
    'G59': require('../audio/g/G-59.mp3'),
    'G60': require('../audio/g/G-60.mp3'),

    'O61': require('../audio/o/O-61.mp3'),
    'O62': require('../audio/o/O-62.mp3'),
    'O63': require('../audio/o/O-63.mp3'),
    'O64': require('../audio/o/O-64.mp3'),
    'O65': require('../audio/o/O-65.mp3'),
    'O66': require('../audio/o/O-66.mp3'),
    'O67': require('../audio/o/O-67.mp3'),
    'O68': require('../audio/o/O-68.mp3'),
    'O69': require('../audio/o/O-69.mp3'),
    'O70': require('../audio/o/O-70.mp3'),
    'O71': require('../audio/o/O-71.mp3'),
    'O72': require('../audio/o/O-72.mp3'),
    'O73': require('../audio/o/O-73.mp3'),
    'O74': require('../audio/o/O-74.mp3'),
    'O75': require('../audio/o/O-75.mp3'),
}

const CALL_DELAY = 5000;

const BingoShowDrawnNumbers = ({ bingo, drawnNumbers, setDrawnNumbers, b, i, n, g, o, isMarkedB, isMarkedI, isMarkedN, isMarkedG, isMarkedO, setIsMarkedB, setIsMarkedI, setIsMarkedN, setIsMarkedG, setIsMarkedO, setCallIntervalID, audio }) => {
    const [ drawnNumber, setDrawnNumber ] = useState(null);

    const dataFetchedRef = useRef(false); //for dev only: avoid double onLoad execution

    useEffect( () => {
        //for dev only: avoid double onLoad execution
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;

        let ii = 0;
        
        setDrawnNumber( bingo[ii] );
        setDrawnNumbers(oldArray => [...oldArray, bingo[ii] ]);

        audio.src = numbersAudio[bingo[ii]];
        audio.play();

        if( bingo[ii].charAt(0) === "B" ) {
            for( let j = 0; j < b.length; j++ ) {
                highlightCalledNumber("B", ii, b[j], setIsMarkedB, isMarkedB, j);
            }
        } else if( bingo[ii].charAt(0) === "I" ) {
            for( let j = 0; j < i.length; j++ ) {
                highlightCalledNumber("I", ii, i[j], setIsMarkedI, isMarkedI, j);
            }
        } else if( bingo[ii].charAt(0) === "N" ) {
            for( let j = 0; j < n.length; j++ ) {
                highlightCalledNumber("N", ii, n[j], setIsMarkedN, isMarkedN, j);
            }
        } else if( bingo[ii].charAt(0) === "G" ) {
            for( let j = 0; j < g.length; j++ ) {
                highlightCalledNumber("G", ii, g[j], setIsMarkedG, isMarkedG, j);
            }
        } else if( bingo[ii].charAt(0) === "O" ) {
            for( let j = 0; j < o.length; j++ ) {
                highlightCalledNumber("O", ii, o[j], setIsMarkedO, isMarkedO, j);
            }
        }

        const newIntervalId = setInterval(() => {
            
            ii++;
            setDrawnNumber( bingo[ii] );
            setDrawnNumbers(oldArray => [...oldArray, bingo[ii] ]);

            audio = new Audio(numbersAudio[bingo[ii]]);
            audio.play();

            if( bingo[ii].charAt(0) === "B" ) {
                for( let j = 0; j < b.length; j++ ) {
                    highlightCalledNumber("B", ii, b[j], setIsMarkedB, isMarkedB, j);
                }
            } else if( bingo[ii].charAt(0) === "I" ) {
                for( let j = 0; j < i.length; j++ ) {
                    highlightCalledNumber("I", ii, i[j], setIsMarkedI, isMarkedI, j);
                }
            } else if( bingo[ii].charAt(0) === "N" ) {
                for( let j = 0; j < n.length; j++ ) {
                    highlightCalledNumber("N", ii, n[j], setIsMarkedN, isMarkedN, j);
                }
            } else if( bingo[ii].charAt(0) === "G" ) {
                for( let j = 0; j < g.length; j++ ) {
                    highlightCalledNumber("G", ii, g[j], setIsMarkedG, isMarkedG, j);
                }
            } else if( bingo[ii].charAt(0) === "O" ) {
                for( let j = 0; j < o.length; j++ ) {
                    highlightCalledNumber("O", ii, o[j], setIsMarkedO, isMarkedO, j);
                }
            }

            if( ii + 1 >= bingo.length ) clearInterval(newIntervalId);
        }, CALL_DELAY);

        setCallIntervalID(newIntervalId);
    }, [] );

    const highlightCalledNumber = (letter, ii, columnLetter, set, isMarked, j) => {
        const index = columnLetter.indexOf(parseInt(bingo[ii].substring(1)));

        if( letter === "N" && index === 2 ) return; //N[2] is the middle star

        let copy = [...isMarked];
        copy[j][index] = 1;

        set(copy);

        //set(prevState => prevState.map((item, idx) => idx === index ? 1 : item ));
    }

    return (
        <>
            <h1 style={{textAlign: 'center', marginTop: '40px'}} >
                { drawnNumber }
            </h1>

            { drawnNumbers.length > 0 && drawnNumbers.map( (n, i) => (
                <span key={i}>{n}, </span>
            )) }
        </>
    );
}
 
export default BingoShowDrawnNumbers;