import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import DocumentActions from "../components/DocumentActions";
import DocumentAttachments from "../components/DocumentAttachments";
import DocDetailsForProcessor from "../components/DocDetailsForProcessor";

const ReceivedDocuments = ({ receivedDocuments, statusIcons, setCheckedDocs, triggerRefreshToggle }) => {
    const [ docDetails, setDocDetails ] = useState(null);
    const [ actions, setActions ] = useState(null);
    const [ attachments, setAttachments ] = useState(null);
    const [ ids, setIds ] = useState(null);

    const [checkedAll, setCheckedAll] = useState(false);
    const [checked, setChecked] = useState( () => {
        let obj = {};

        for( let i = 0; i < receivedDocuments.length; i++ ) {
            const name = "k" + receivedDocuments[i].doc_id.replace("-", "_");
            obj = { ...obj, [name]: false };
        }

        return obj;
    });

    const toggleCheck = (inputName) => {
        setChecked((prevState) => {
            const newState = { ...prevState };
            newState[inputName] = !prevState[inputName];
            return newState;
        });

        setDocDetails(null); //remove details
    };

    const selectAll = (value) => {
        setCheckedAll(value);
        setChecked((prevState) => {
            const newState = { ...prevState };
            for (const inputName in newState) {
                newState[inputName] = value;
            }
            return newState;
        });

        setDocDetails(null); //remove details
    };

    useEffect( () => {
        let allChecked = true;
        let gotIntoForLoop = false;
        let checkedDocs = [];

        for (const inputName in checked) {
            if (checked[inputName] === false) allChecked = false;
            if (checked[inputName] === true) checkedDocs.push( inputName.replace("_", "-").substring(1) );

            gotIntoForLoop = true;
        }

        if (allChecked && gotIntoForLoop) setCheckedAll(true);
        else setCheckedAll(false);

        setCheckedDocs(checkedDocs);
    }, [checked] );

    useEffect( () => {
        if(actions) setActions(null);
        if(attachments) setAttachments(null);
    }, [triggerRefreshToggle] );

    const statusIcon = (memo_effstatus) => {
        let icon = "iconNoWarnBlue"; //default

        if( memo_effstatus === "W" ) icon = "iconWarnYellow";
        else if( memo_effstatus === "FM" ) icon = "iconMemoRed";
        else if( memo_effstatus === "PEM" ) icon = "iconPemOrange";

        return icon;
    }

    const getDocumentDetails = (doc_id) => {
        fetch(`/api/dts-action/get-doc-details/${doc_id}`)
            .then( res => res.json() )
            .then( res => {
                if( 'doc_details' in res ) {
                    setDocDetails(res.doc_details);
                    setIds( { doc_id, doc_type_id: res.doc_details.doc_type_id } );

                    if( 'actions' in res ) setActions( res.actions );
                    if( 'attachments' in res ) setAttachments(res.attachments);
                }
            })
        ;
    }

    return (
        <div>
            { receivedDocuments.length === 0 &&
                <div className="text-align-center mb-2">
                    <span className="fst-italic">No received documents.</span>
                </div>
            }

            { receivedDocuments.length > 0 &&
                <div style={s.container}>
                    <table style={s.table}>
                        <tr style={s.tr}>
                            <th style={{ width: '5%' }}><input type="checkbox" onChange={(e) => selectAll(e.target.checked)} checked={checkedAll} /></th>
                            <th style={{ width: '8%' }}>Status</th>
                            <th style={{ width: '13%' }}>Reference #</th>
                            <th style={{ width: '35%' }}>Title</th>
                            <th style={{ width: '15%' }}>Forwarded By</th>
                            <th style={{ width: '20%' }}>Date Received</th>
                            <th style={{ width: '5%' }}></th>
                        </tr>
                        { receivedDocuments.map( (rd, i) => (
                            <tr key={i} style={ receivedDocuments.length === i + 1 ? {...s.tr, borderBottom: 'none'} : s.tr }>
                                <td><input type="checkbox" onChange={() => toggleCheck("k" + rd.doc_id.replace("-", "_"))} checked={checked["k" + rd.doc_id.replace("-", "_")]} name={ "k" + rd.doc_id.replace("-", "_") } /></td>
                                <td><img src={ statusIcons[ statusIcon(rd.memo_effstatus) ] } alt="Status Icon" /></td>
                                <td>{rd.doc_id}</td>
                                <td>{rd.title}</td>
                                <td>{rd.dept_title}</td>
                                <td>{rd.in_dt}</td>
                                <td>
                                    <Link to="#" onClick={ () => getDocumentDetails(rd.doc_id) }>Details</Link>
                                </td>
                            </tr>
                        )) }
                        
                    </table>
                </div>
            }

            { docDetails && <DocDetailsForProcessor docDetails={docDetails} /> }

            { attachments && <DocumentAttachments attachments={attachments} ids={ids} getDocumentDetails={getDocumentDetails} /> }

            { actions && <DocumentActions actions={actions} ids={ids} /> }

        </div>
    );
}

const s = { //styles
    container: {
        backgroundColor: 'rgb(234 248 255)',
        width: '100%',
        padding: '5px 10px',
        border: '1px solid #bbb',
        borderRadius: '10px'
    },
    table: {
        width: '100%',
    },
    tr: {
        borderBottom: '1px solid #bbb',
        lineHeight: '2rem'
    },
    tdLeft: {
        textAlign: 'right',
        paddingRight: '6px',
        width: '25%',
        fontWeight: 'bold'
    }
}

export default ReceivedDocuments;