
const DocumentDetails = ({ docDetails }) => {
    if( docDetails.effstatus === 'A' ) docDetails.effstatus = 'Active';
    else if( docDetails.effstatus === 'AR' ) docDetails.effstatus = 'Archived';

    return (
        <fieldset className="mt-3">
            <legend>Document Details</legend>
            <div style={s.container}>
                <table style={s.table}>
                    <tr style={s.tr}>
                        <td style={s.tdLeft}>Document ID:</td>
                        <td>{docDetails.doc_id}</td>
                    </tr>
                    <tr style={s.tr}>
                        <td style={s.tdLeft}>Title:</td>
                        <td>{docDetails.title}</td>
                    </tr>
                    <tr style={s.tr}>
                        <td style={s.tdLeft}>Description:</td>
                        <td>{docDetails.descr}</td>
                    </tr>
                    <tr style={s.tr}>
                        <td style={s.tdLeft}>Remarks/Notes:</td>
                        <td>{docDetails.remarks}</td>
                    </tr>
                    <tr style={s.tr}>
                        <td style={s.tdLeft}>Received Date/Time:</td>
                        <td>{docDetails.created_dt2}</td>
                    </tr>
                    <tr style={s.tr}>
                        <td style={s.tdLeft}>Requestor:</td>
                        <td>{docDetails.requestor}</td>
                    </tr>
                    <tr style={s.tr}>
                        <td style={s.tdLeft}>Position:</td>
                        <td>{docDetails.position}</td>
                    </tr>
                    <tr style={s.tr}>
                        <td style={s.tdLeft}>Office:</td>
                        <td>{docDetails.office}</td>
                    </tr>
                    <tr style={s.tr}>
                        <td style={s.tdLeft}>Address:</td>
                        <td>{docDetails.address}</td>
                    </tr>
                    <tr style={s.tr}>
                        <td style={s.tdLeft}>Contact Number:</td>
                        <td>{docDetails.contact_num}</td>
                    </tr>
                    <tr style={{...s.tr, borderBottom: 'none'}}>
                        <td style={s.tdLeft}>Status:</td>
                        <td>{docDetails.effstatus}</td>
                    </tr>
                </table>
            </div>
            
        </fieldset>
    );
}

const s = { //styles
    container: {
        backgroundColor: 'rgb(234 248 255)',
        width: '100%',
        padding: '5px 10px',
        border: '1px solid #bbb',
        borderRadius: '10px'
    },
    table: {
        width: '100%',
    },
    tr: {
        borderBottom: '1px solid #bbb',
        lineHeight: '2rem'
    },
    tdLeft: {
        textAlign: 'right',
        paddingRight: '6px',
        width: '25%'
    }
}
 
export default DocumentDetails;