import { Link } from "react-router-dom";

const DocAdvSearchResults = ({ advSearchResult, basicSearch }) => {

    return (
        <fieldset className="mt-3">
            <legend>Search Result</legend>
            { advSearchResult.length > 0 &&
                <div style={s.container}>
                    <table style={s.table}>
                        <tr style={s.tr}>
                            <th style={{ width: '15%' }}>Document ID</th>
                            <th style={{ width: '30%' }}>Title</th>
                            <th style={{ width: '25%' }}>Created Date/Time</th>
                            <th style={{ width: '20%' }}>Requesting Office</th>
                            <th style={{ width: '10%' }}>Status</th>
                        </tr>
                        { advSearchResult.map( (asr, i) => (
                            <tr key={i} style={ advSearchResult.length === i + 1 ? {...s.tr, borderBottom: 'none'} : s.tr }>
                                <td>{asr.doc_id}</td>
                                <td>
                                    <Link to="#" onClick={ () => basicSearch(asr.doc_id) }>{asr.title}</Link>
                                </td>
                                <td>{asr.created_dt_tm}</td>
                                <td>{asr.office}</td>
                                <td>{asr.effstatus}</td>

                                
                                
                            </tr>
                        )) }
                    </table>
                </div>
            }

            { advSearchResult.length === 0 && <br /> }
        </fieldset>
    );
}

const s = { //styles
    container: {
        backgroundColor: 'rgb(234 248 255)',
        width: '100%',
        padding: '5px 10px',
        border: '1px solid #bbb',
        borderRadius: '10px'
    },
    table: {
        width: '100%',
    },
    tr: {
        borderBottom: '1px solid #bbb',
        lineHeight: '2rem'
    },
    tdLeft: {
        textAlign: 'right',
        paddingRight: '6px',
        width: '25%'
    }
}

export default DocAdvSearchResults;