import { useEffect } from 'react'
import { useNavigate } from "react-router-dom";

const SignOut = () => {
    const navigate = useNavigate();

    useEffect( () => {
        fetch('/api/signout')
            .then( res => { return res.json() } )
            .then( status => {
                if( status && status.isSignedOut ) {
                    navigate('/');
                }
            } )
        ;
    }, []);

    return null;
}

export default SignOut;