import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Help from './Help';

import '../css/dts.css';
import imgCurve1 from '../images/curve-1.png';
import imgCurve2 from '../images/curve-2.png';

let icons = {
    'new-user': require('../images/icons/newuser.png'),
    'edit-profile': require('../images/icons/editprofile.png'),
    'change-password': require('../images/icons/changepassword.png'),
    'main-doc': require('../images/icons/maindoc.png'),
    'doc-track': require('../images/icons/doctrack.png'),
    'receiving': require('../images/icons/receiving.png'),
    'action': require('../images/icons/action.png'),
    'work-history': require('../images/icons/workhistory.png'),
    'admin': require('../images/icons/admin.png')
}

const FolderLayout = ({ children, page }) => {
    const [ frontPage, setFrontPage ] = useState(null);
    const [ backPages, setBackPages ] = useState([]);

    useEffect( () => {
        fetch('/api/get-pages')
            .then( res => res.json() )
            .then( res => {
                if(res) {
                    setFrontPage( res.pages.filter( (p) => p.page === page )[0] );
                    setBackPages( res.pages.filter( (p) => p.page !== page ) );
                }
            })
        ;
    }, [] );

    return (
        <div className="page-content" style={{ paddingTop: `${backPages.length * 30 + 12 }px`, paddingLeft: `${backPages.length * 10 + 12 }px` }}>
            { frontPage &&
                <div className="front-box">
                    <div className="front-box-top" style={{ zIndex: `${backPages.length + 1 }` }}>
                        <div className="front-box-top-left">
                            <img className="ml-3" src={ icons[frontPage.page] } alt="New user icon" />
                            <Link to={frontPage.path} className="ml-1">{frontPage.title}</Link>
                        </div>
                        
                        <div className="front-box-top-right">
                            <div className="front-box-top-right-curve" style={styles.frontBoxTopRightCurve}></div>
                            <Link to={frontPage.path} className="ml-5">{frontPage.descr}</Link>
                            {/* <a href="#" className="help">
                                <img className="ml-3" src={iconHelp} alt="Help" />
                            </a> */}
                            <Help page={page} />
                        </div>

                    </div>
                    <div className="box-content" style={{ zIndex: `${backPages.length + 1 }` }}>
                        <div style={{padding:'0 10px 22px 10px'}}>
                            {children}
                        </div>
                    </div>

                    <div className="front-box-bottom" style={{ zIndex: `${backPages.length + 1 }` }}></div>

                    { backPages.map( (b, index) => (
                        <>
                            <div className="back-box-left" style={{ zIndex: `${index * -1 + backPages.length }`, top: `${ (index + 1) * -30 }px`, left: `${ (index + 1) * -10 }px`}}></div>
                            <div className="back-box-top" style={{ zIndex: `${index * -1 + backPages.length }`, top: `${ (index + 1) * -30 }px`, left: `${ (index + 1) * -10 }px`}}>
                                <div className="back-box-top-left">
                                    <img className="ml-3" alt="New user icon" src={ icons[b.page] } />
                                    <Link to={b.path} className="ml-1">{b.title}</Link>
                                </div>
                                <div className="back-box-top-right">
                                    <div className="front-box-top-right-curve" style={styles.backBoxTopRightCurve}></div>
                                    <Link to={b.path} className="ml-5">{b.descr}</Link>
                                </div>
                            </div>
                        </>
                    )) }
                    
                </div>
            }
        </div>
    );
}

const styles = {
    frontBoxTopRightCurve: {
        background: `url(${imgCurve1}) no-repeat`
    },
    backBoxTopRightCurve: {
        background: `url(${imgCurve2}) no-repeat`
    }
}
 
export default FolderLayout;