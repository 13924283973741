import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';

import iconClose from "../images/icons/close.png";
import iconSuccess from '../images/icons/success.png';

const DocumentActions = ({ actions, ids }) => {
    const [ showModalSuccess, setShowModalSuccess ] = useState("");
    const [ showAddActionModal, setShowAddActionModal ] = useState(false);

    const [ actionList, setActionList ] = useState([]);
    const [ selectedAction, setSelectedAction ] = useState("");
    const [ remarks, setRemarks ] = useState("");

    useEffect( () => {
        fetch(`/api/dts-action/get-action-list/${ids.doc_type_id}`)
            .then( res => res.json() )
            .then( res => {
                if( 'actions' in res ) { //action list is available
                    setActionList(res.actions);
                    setSelectedAction(res.actions[0].action_id); //set default
                }
            })
        ;
    }, [] );

    const addStatus = () => {
        fetch('/api/dts-action/add-action',
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ doc_id: ids.doc_id, action_id: selectedAction, remarks })
            }).then( res => res.json() )
            .then( res => {
                if( 'successfulInsert' in res && res.successfulInsert ) { //action list is available
                    setShowModalSuccess("You have successfully added an action.");

                    //clear and hide add action modal
                    setSelectedAction("");
                    setRemarks("");
                    setShowAddActionModal(false);
                }
            })
        ;
    }

    const modalAddAction = () => {
        return (
            <Modal show={showAddActionModal} onHide={ () => setShowAddActionModal(false) } backdrop="static" keyboard={false} centered>
                <Modal.Body>
                    <div className="text-align-right">
                        <input type="image" src={iconClose} onClick={ () => setShowAddActionModal(false) } />
                    </div>

                    <div className="d-flex justify-content-end align-items-center mt-3">
                        <label className="text-align-right">Actions:</label>
                        <select value={selectedAction} onChange={ (e) => setSelectedAction(e.target.value) } className="width-80 ml-2">
                            { actionList.length > 0 && actionList.map( (al, i) => (
                                <option key={i} value={al.action_id}>{al.action_name}</option>
                            ) ) }
                        </select>
                    </div>

                    <div className="d-flex justify-content-end align-items-center mt-3">
                        <label className="text-align-right">Remarks</label>
                        <textarea rows="4" className="width-80 ml-2" value={remarks} onChange={ (e) => setRemarks(e.target.value) }></textarea>
                    </div>

                    <div className="d-flex justify-content-end align-items-center mt-3 mb-4">
                        <label></label>
                        <div className="width-80 ml-2">
                            <button onClick={addStatus}>Add Status</button>
                            <button className="ml-2" onClick={ () => setShowAddActionModal(false) }>Cancel</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    };

    const modalSuccess = () => {
        return (
            <Modal show={showModalSuccess} onHide={ () => setShowModalSuccess("") } backdrop="static" keyboard={false} centered>
                <Modal.Body>
                    <div className="text-align-right">
                        <input type="image" src={iconClose} onClick={ () => setShowModalSuccess("") } />
                    </div>
                    <div className="mt-4 mb-3">
                        <div className="mb-2 text-align-center">
                            <img src={iconSuccess} alt="Success" />
                            <span className="ml-2 bold" style={{ color: 'rgb(0, 72, 255)', fontSize: '1.2rem' }}>{showModalSuccess}</span>
                        </div>
                    </div>
                    <div className="mb-3 text-align-center">
                        <button className="ml-2" onClick={ () => setShowModalSuccess("") }>Okay</button>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }

    return (
        <fieldset className="mt-3">
            <legend>Document Actions/Status</legend>
            
            <div className="text-align-right">
                <Link to="#" className="bold mr-2" onClick={ () => setShowAddActionModal(true) }>Add Action/Status</Link>
            </div>
            
            { actions.length === 0 &&
                <div className="mt-2 text-align-center">
                    <i>No available actions</i>
                </div>
            }

            { actions.length > 0 &&
                <div className="mt-2" style={s.container}>
                    <table style={s.table}>
                        <tr style={s.tr}>
                            <th style={{ width: '20%' }}>Action</th>
                            <th style={{ width: '50%' }}>Remarks</th>
                            <th style={{ width: '30%' }}>Acted Date</th>
                        </tr>
                        { actions.map( (a, i) => (
                            <tr key={i} style={ actions.length === i + 1 ? {...s.tr, borderBottom: 'none'} : s.tr }>
                                <td>{a.action_name}</td>
                                <td>{a.remarks}</td>
                                <td>{a.acted_dt}</td>
                            </tr>
                        )) }
                    </table>
                </div>
            }

            { modalAddAction() }

            { modalSuccess() }
        </fieldset>
    );
}

const s = { //styles
    container: {
        backgroundColor: 'rgb(234 248 255)',
        width: '100%',
        padding: '5px 10px',
        border: '1px solid #bbb',
        borderRadius: '10px'
    },
    table: {
        width: '100%',
    },
    tr: {
        borderBottom: '1px solid #bbb',
        lineHeight: '2rem'
    }
}
 
export default DocumentActions;