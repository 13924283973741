import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const WarningsAndForMemos = ({ statusIcons, setDocDetails, setWarningID, reFetch }) => {
    const [ warningsAndForMemos, setWarningsAndForMemos ] = useState([]);

    useEffect( () => {
        fetch('/api/dts-admin/get-warnings-and-for-memos')
            .then( res => res.json() )
            .then( res => {
                if( res.warnings_and_for_memos ) setWarningsAndForMemos(res.warnings_and_for_memos);
            })
        ;
    }, [reFetch] );

    const statusIcon = (status) => {
        let icon = "";

        if( status === "W" ) icon = "iconWarnYellow";
        else if( status === "FM" ) icon = "iconMemoRed";
        else if( status === "PEM" ) icon = "iconPemOrange";

        return icon;
    }

    const getDocumentDetails = (warning_id) => {
        setWarningID(warning_id);

        fetch(`/api/dts-admin/get-doc-details/${warning_id}`)
            .then( res => res.json() )
            .then( res => {
                if( 'doc_details' in res ) {
                    setDocDetails(res.doc_details);

                    // setIds( { doc_id, doc_type_id: res.doc_details.doc_type_id } );

                    // if( 'actions' in res ) setActions( res.actions );
                    // if( 'attachments' in res ) setAttachments(res.attachments);
                }
            })
        ;
    }

    return (
        <fieldset className="mt-3">
            <legend>Warnings/For Memos</legend>
            
            { warningsAndForMemos.length === 0 &&
                <div className="text-align-center bold mb-2">
                    <i>There are no warnings and for memos.</i>
                </div>
            }

            { warningsAndForMemos.length > 0 &&
                <div style={s.container}>
                    <table style={s.table}>
                        <tr style={s.tr}>
                            <th style={{ width: '10%' }}>Status</th>
                            <th style={{ width: '20%' }}>Document ID</th>
                            <th style={{ width: '60%' }}>Document Title</th>
                            <th style={{ width: '10%' }}></th>
                        </tr>
                        { warningsAndForMemos.map( (wafm, i) => (
                            <tr key={i} style={ warningsAndForMemos.length === i + 1 ? {...s.tr, borderBottom: 'none'} : s.tr }>
                                <td><img src={ statusIcons[ statusIcon(wafm.effstatus) ] } alt="Status Icon" /></td>
                                <td>{wafm.doc_id}</td>
                                <td>{wafm.title}</td>
                                <td>
                                    <Link to="#" onClick={ () => getDocumentDetails(wafm.warning_id) }>Details</Link>
                                </td>
                            </tr>
                        )) }
                        
                    </table>
                </div>
            }
        </fieldset>
    );
}

const s = { //styles
    container: {
        backgroundColor: 'rgb(234 248 255)',
        width: '100%',
        padding: '5px 10px',
        border: '1px solid #bbb',
        borderRadius: '10px'
    },
    table: {
        width: '100%',
    },
    tr: {
        borderBottom: '1px solid #bbb',
        lineHeight: '2rem'
    },
    tdLeft: {
        textAlign: 'right',
        paddingRight: '6px',
        width: '25%',
        fontWeight: 'bold'
    }
}
 
export default WarningsAndForMemos;