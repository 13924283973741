import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import "bootstrap/dist/css/bootstrap.min.css";
import "./css/override-bootstrap.css";

import EnsureAuthenticated from './components/EnsureAuthenticated';
import ForwardAuthenticated from './components/ForwardAuthenticated';

import Action from './pages/Action';
import Admin from './pages/Admin';
import ChangePassword from './pages/ChangePassword';
import DocumentTracking from './pages/DocumentTracking';
import EditProfile from './pages/EditProfile';
import ForgotPassword from './pages/ForgotPassword';
import HomePage from './pages/HomePage';
import MainDocument from './pages/MainDocument';
import NewUser from './pages/NewUser';
import Receiving from './pages/Receiving';
import SignOut from './pages/SignOut';
import WorkHistory from './pages/WorkHistory';

import Bingo from './pages/Bingo';

function App() {
    const [ isAuthenticated, setIsAuthenticated ] = useState(null);
    const [ reAuthToggle, setReAuthToggle ] = useState(true); //toggle used to trigger re-authentication

    const [ path, setPath ] = useState('');
    const [ name, setName ] = useState('');
    const [ department, setDepartment ] = useState('');

    useEffect( () => {
        fetch('/api/verify-user-authentication-cookie')
            .then( res => { return res.json(); } )
            .then( user => { 
                setIsAuthenticated( user.isAuthenticated );
                setPath( user.path );
                setName( user.name );
                setDepartment( user.department );
            } );
    }, [reAuthToggle] );

    return (
        <Router>
            <Routes>
                <Route path="/not-part-of-dts/bingo" element={<Bingo />} />
                

                <Route path="/action" element={
                    <EnsureAuthenticated isAuthenticated={isAuthenticated} reAuthToggle={reAuthToggle} setReAuthToggle={setReAuthToggle}>
                        <Action properties={{ name, department }} />
                    </EnsureAuthenticated>
                } />
                <Route path="/admin" element={
                    <EnsureAuthenticated isAuthenticated={isAuthenticated} reAuthToggle={reAuthToggle} setReAuthToggle={setReAuthToggle}>
                        <Admin properties={{ name, department }} />
                    </EnsureAuthenticated>
                } />
                <Route path="/change-password" element={
                    <EnsureAuthenticated isAuthenticated={isAuthenticated} reAuthToggle={reAuthToggle} setReAuthToggle={setReAuthToggle}>
                        <ChangePassword properties={{ name, department }} />
                    </EnsureAuthenticated>
                } />
                <Route path="/doc-track" element={
                    <EnsureAuthenticated isAuthenticated={isAuthenticated} reAuthToggle={reAuthToggle} setReAuthToggle={setReAuthToggle}>
                        <DocumentTracking properties={{ name, department }} />
                    </EnsureAuthenticated>
                } />
                <Route path="/edit-profile" element={
                    <EnsureAuthenticated isAuthenticated={isAuthenticated} reAuthToggle={reAuthToggle} setReAuthToggle={setReAuthToggle}>
                        <EditProfile properties={{ name, department }} />
                    </EnsureAuthenticated>
                } />
                <Route path="/forgot-password" element={
                    // <EnsureAuthenticated isAuthenticated={isAuthenticated} reAuthToggle={reAuthToggle} setReAuthToggle={setReAuthToggle}>

                        <ForgotPassword />
                    // </EnsureAuthenticated>
                } />
                <Route path="/main-doc" element={
                    <EnsureAuthenticated isAuthenticated={isAuthenticated} reAuthToggle={reAuthToggle} setReAuthToggle={setReAuthToggle}>
                        <MainDocument properties={{ name, department }} />
                    </EnsureAuthenticated>
                } />
                <Route path="/new-user" element={
                    <EnsureAuthenticated isAuthenticated={isAuthenticated} reAuthToggle={reAuthToggle} setReAuthToggle={setReAuthToggle}>
                        <NewUser newUserProps={{ name, department }} />
                    </EnsureAuthenticated>
                } />
                <Route path="/receiving" element={
                    <EnsureAuthenticated isAuthenticated={isAuthenticated} reAuthToggle={reAuthToggle} setReAuthToggle={setReAuthToggle}>
                        <Receiving properties={{ name, department }} />
                    </EnsureAuthenticated>
                } />
                <Route path="/work-history" element={
                    <EnsureAuthenticated isAuthenticated={isAuthenticated} reAuthToggle={reAuthToggle} setReAuthToggle={setReAuthToggle}>
                        <WorkHistory properties={{ name, department }} />
                    </EnsureAuthenticated>
                } />
                <Route path="/" element={
                    <ForwardAuthenticated isAuthenticated={isAuthenticated} reAuthToggle={reAuthToggle} setReAuthToggle={setReAuthToggle} path={path}>
                        <HomePage homePageProps={{ name, setName, department, setDepartment }} />
                    </ForwardAuthenticated>
                } />
                
                <Route path="/signout" element={<SignOut />} />
            </Routes>
        </Router>
    );
}

export default App;
