import Footer from '../components/Footer';
import Header from '../components/Header';
import Login from '../components/Login';

import bannerImage from '../images/clientBanner_2.jpeg';

const HomePage = ({ homePageProps }) => {
    return (
        <div className="home-page container">
            <Header name={homePageProps.name} department={homePageProps.department} />

            <div className="page-content">
                <img className="home-banner" src={bannerImage} alt="Banner" />

                <div className="home-content mt-3 mr-4">
                    <div>
                        <h2 className="color-blue">Overview</h2>
                        <p className="mt-1">
                            {/* The Document Tracking System (DTS) facilitates the tracking of various official
                            documents of the provincial government of Leyte as they flow from one department
                            to another resulting in increased efficiency of official transactions and prevent
                            loss of documents. */}

                            The Document Tracking System (DTS) facilitates the tracking of various documents
                            as they flow from one department to another resulting in increased efficiency of
                            transactions and prevent loss of documents.
                        </p>

                        <h2 className="color-blue mt-4">Features</h2>
                        <p className="mt-1">
                            <ul>
                                <li>Automated generation of unique document identifier (Reference Number) with audit feature</li>
                                <li>Easy retrieval by management of document information and status</li>
                                <li>Automated warning and memo systems</li>
                                <li>Alerts to recipient departments on incoming documents for action before they physically arrive</li>
                                <li>Role-based access to the system</li>
                                <li>Easy forwarding documents from one station to another</li>
                            </ul>
                        </p>
                    </div>

                    <Login setName={homePageProps.setName} setDepartment={homePageProps.setDepartment} />
                </div>

                
            </div>
            
            <Footer />
        </div>
    );
}

export default HomePage;