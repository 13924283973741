import { Link } from "react-router-dom";

const SearchAttachmentResults = ({ attachSearchResult, setShowDocDetails, setDocDetails, setTransHistory }) => {
    const handleShowDetails = (docID) => {
        fetch(`/api/dts-work-history/basic-search/${docID}`)
            .then( res => res.json() )
            .then( res => {
                if( res.docDetails ) setDocDetails(res.docDetails);
                if( res.transHistory ) setTransHistory(res.transHistory);

                setShowDocDetails(true);
            })
        ;
    }

    return (
        <fieldset className="mt-3">
            <legend>Search Attachment Results</legend>

            { attachSearchResult.length > 0 &&
                <div style={s.container}>
                    <table style={s.table}>
                        <tr style={s.tr}>
                            <th style={{ width: '25%', lineHeight: '1.5rem' }}>Attachments /<br />Supporting<br /> Documents</th>
                            <th style={{ width: '15%', lineHeight: '1.5rem' }}>Reference Number</th>
                            <th style={{ width: '15%' }}>Document ID</th>
                            <th style={{ width: '20%' }}>Added Date/Time</th>
                            <th style={{ width: '25%' }}>Remarks</th>
                        </tr>

                        { attachSearchResult.map( (asr, i) => (
                            <tr key={i} style={ attachSearchResult.length === i + 1 ? {...s.tr, borderBottom: 'none'} : s.tr }>
                                <td>{ asr.attach_title }</td>
                                <td>{ asr.ref_num }</td>
                                <td>
                                    <Link to="#" onClick={ () => handleShowDetails(asr.doc_id) }>{asr.doc_id}</Link>
                                </td>
                                <td>{ asr.added_dt_2 }</td>
                                <td>{ asr.attach_descr }</td>
                            </tr>
                        )) }

                        {/* <td>
                            <Link to="#" onClick={ () => setShowDocDetails(true) }>{ docDetails.title }</Link>
                        </td> */}
                    </table>
                </div>
            }
        </fieldset>
    );
}

const s = { //styles
    container: {
        backgroundColor: 'rgb(234 248 255)',
        width: '100%',
        padding: '5px 10px',
        border: '1px solid #bbb',
        borderRadius: '10px'
    },
    table: {
        width: '100%',
    },
    tr: {
        borderBottom: '1px solid #bbb',
        lineHeight: '2rem'
    }
}
 
export default SearchAttachmentResults;