import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Help from '../components/Help';

const Login = ({ setName, setDepartment }) => {
    const navigate = useNavigate();

    const [ username, setUsername ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ error, setError ] = useState('');

    const [ isLoading, setIsLoading ] = useState(false);
    
    const handleSubmit = (e) => {
        e.preventDefault(); //will prevent default page load

        if( username && password ) {
            setIsLoading(true);
            setError("");

            const login = { username, password };

            fetch('/api/login', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(login)
            })
            .then( (result) => result.json() )
            .then( (result) =>  {
                if( 'err_msg' in result ) { //check if invalid credentials
                    setError( result.err_msg );
                    setIsLoading(false);
                }
                else { //successful sign in, redirect
                    setName(result.name);
                    setDepartment(result.department);
                    navigate(result.path);
                }
            });
        } else {
            setError("Please enter your username and password.");
        }
        
        
    }

    return(
        <div className="login">
            <div className="login-header">
                <span className="bold ml-3">Login to DTS</span>
                {/* <Link to="help" className="link1 mr-3">Help</Link> */}
                <Help page={"login"} />
            </div>
            <div className="login-body">
                <form onSubmit={ handleSubmit }>
                    <div>
                        <label for="username" className="bold">Enter Username:</label>
                        <input type="text" id="username" value={username} onChange={ (e) => setUsername(e.target.value) } />
                    </div>
                    <div className="mt-3">
                        <label for="password" className="bold">Enter Password:</label>
                        <input type="password" id="password" value={password} onChange={ (e) => setPassword(e.target.value) } />
                    </div>
                    <div className="mt-1">
                        <Link to="forgot-password" className="link2">Forgot your password?</Link>
                    </div>
                    <hr />
                    <div className="mr-3 mt-2" style={{textAlign: 'right'}}>
                        { !isLoading &&
                            <button>Login</button>
                        }
                        { isLoading && 
                            <button disabled>
                                <i class="fas fa-spinner fa-spin"></i> Loading
                            </button>
                        }
                    </div>
                    { error &&
                        <span className="login-error mt-1">{error}</span>
                    }
                    {/* Username and password combination is not recognized. */}
                </form>
            </div>
            <div className="login-footer"></div>
        </div>
    );
}

export default Login;