import { useState, useEffect, useRef } from 'react';
import BingoCard from './BingoCard';
import BingoShowDrawnNumbers from './BingoShowDrawnNumbers';

import './bingo.css';

const MAX_NUM_CARDS = 5;

const CARD_WIDTH = 352;

const Bingo = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        function handleWindowResize() {
            setWindowWidth(window.innerWidth);
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
    
    let audio = new Audio();

    const dataFetchedRef = useRef(false); //for dev only: avoid double onLoad execution

    useEffect( () => {
        //for dev only: avoid double onLoad execution
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        
        generateCardNumbers();
    }, []);

    const generateCardNumbers = (ind) => {
        generateFiveNumbers(1, 15, setB, b, ind);
        generateFiveNumbers(16, 30, setI, i, ind);
        generateFiveNumbers(31, 45, setN, n, ind);
        generateFiveNumbers(46, 60, setG, g, ind);
        generateFiveNumbers(61, 75, setO, o, ind);

        // "B" (numbers 1–15)
        // "I" (numbers 16–30)
        // "N" (numbers 31–45)
        // "G" (numbers 46–60)
        // "O" (numbers 61–75)
    }

    const generateFiveNumbers = ( min, max, set, letter, ind ) => {
        let newArr = [];
        for( let i = 0; i < 5; i++ ) {
            let x;
            do {
                x = Math.floor(Math.random() * (max - min + 1) + min);
            } while( newArr.includes(x) );

            newArr.push(x);
        }

        if( ind !== undefined ) { //regenerate numbers of existing card
            let copy = [...letter];
            copy[ind] = newArr;
            set( copy );
        } else { //add a new  card
            set( current => [ ...current, newArr ] );

            setIsMarkedB( current => [ ...current, [ 0, 0, 0, 0, 0 ] ] );
            setIsMarkedI( current => [ ...current, [ 0, 0, 0, 0, 0 ] ] );
            setIsMarkedN( current => [ ...current, [ 0, 0, 2, 0, 0 ] ] );
            setIsMarkedG( current => [ ...current, [ 0, 0, 0, 0, 0 ] ] );
            setIsMarkedO( current => [ ...current, [ 0, 0, 0, 0, 0 ] ] );
        }
    }

    const [ b, setB ] = useState([]);
    const [ i, setI ] = useState([]);
    const [ n, setN ] = useState([]);
    const [ g, setG ] = useState([]);
    const [ o, setO ] = useState([]);

    const [ isMarkedB, setIsMarkedB ] = useState([]);
    const [ isMarkedI, setIsMarkedI ] = useState([]);
    const [ isMarkedN, setIsMarkedN ] = useState([]);
    const [ isMarkedG, setIsMarkedG ] = useState([]);
    const [ isMarkedO, setIsMarkedO ] = useState([]);

    const bingoNumbers = [ 
        'B1', 'B2', 'B3', 'B4', 'B5', 'B6', 'B7', 'B8', 'B9', 'B10', 'B11', 'B12', 'B13', 'B14', 'B15',
        'I16', 'I17', 'I18', 'I19', 'I20', 'I21', 'I22', 'I23', 'I24', 'I25', 'I26', 'I27', 'I28', 'I29', 'I30',
        'N31', 'N32', 'N33', 'N34', 'N35', 'N36', 'N37', 'N38', 'N39', 'N40', 'N41', 'N42', 'N43', 'N44', 'N45',
        'G46', 'G47', 'G48', 'G49', 'G50', 'G51', 'G52', 'G53', 'G54', 'G55', 'G56', 'G57', 'G58', 'G59', 'G60',
        'O61', 'O62', 'O63', 'O64', 'O65', 'O66', 'O67', 'O68', 'O69', 'O70', 'O71', 'O72', 'O73', 'O74', 'O75'
    ];

    const [ bingo, setBingo ] = useState([]);

    const [ startGame, setStartGame ] = useState(false);
    const [ seconds, setSeconds ] = useState(5);

    const [ drawnNumbers, setDrawnNumbers ] = useState([]);

    const [ callIntervalID, setCallIntervalID ] = useState(null);

    const generateRandomNumberSequence = () => {
        let arrBingoNumbers = [ ...bingoNumbers ]; //copy
        let randomBingoNumbers = [];

        for( let i = 0; i < bingoNumbers.length; i++ ) {
            const randIndex = getRandomNumber(0, arrBingoNumbers.length - 1);
            randomBingoNumbers.push( arrBingoNumbers[randIndex] ); //push
            arrBingoNumbers.splice(randIndex, 1); //remove
        }

        setBingo(randomBingoNumbers);
    }

    const getRandomNumber = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    const handleStartGame = () => {
        audio.src = require('../audio/lets-play-bingo.mp3');
        audio.play();

        setStartGame(true);

        let i = 5;

        const interval = setInterval(() => {
            setSeconds(seconds => seconds - 1);
            i--;
            if( i === 0 ) {
                clearInterval(interval);
                generateRandomNumberSequence();
            }
        }, 1000);
    }

    const handleAddCard = () => {
        generateCardNumbers();
    }

    const remove = (index) => {
        let bCopy = [...b];
        bCopy.splice(index, 1);

        let iCopy = [...i];
        iCopy.splice(index, 1);

        let nCopy = [...n];
        nCopy.splice(index, 1);

        let gCopy = [...g];
        gCopy.splice(index, 1);
        
        let oCopy = [...o];
        oCopy.splice(index, 1);

        setB( bCopy );
        setI( iCopy );
        setN( nCopy );
        setG( gCopy );
        setO( oCopy );

        //isMarked
        bCopy = [...isMarkedB];
        bCopy.splice(index, 1);

        iCopy = [...isMarkedI];
        iCopy.splice(index, 1);

        nCopy = [...isMarkedN];
        nCopy.splice(index, 1);

        gCopy = [...isMarkedG];
        gCopy.splice(index, 1);
        
        oCopy = [...isMarkedO];
        oCopy.splice(index, 1);

        setIsMarkedB( bCopy );
        setIsMarkedI( iCopy );
        setIsMarkedN( nCopy );
        setIsMarkedG( gCopy );
        setIsMarkedO( oCopy );
    }

    return (
        <>
            { startGame && seconds > 0 &&
                <h3 className="text-align-center mt-4">Will start in {seconds}.</h3>
            }

            { bingo.length > 0 &&
                <BingoShowDrawnNumbers
                    bingo={bingo}
                    drawnNumbers={drawnNumbers}
                    setDrawnNumbers={setDrawnNumbers}

                    b={b} i={i} n={n} g={g} o={o}

                    isMarkedB={isMarkedB}
                    isMarkedI={isMarkedI}
                    isMarkedN={isMarkedN}
                    isMarkedG={isMarkedG}
                    isMarkedO={isMarkedO}
                    
                    setIsMarkedB={setIsMarkedB}
                    setIsMarkedI={setIsMarkedI}
                    setIsMarkedN={setIsMarkedN}
                    setIsMarkedG={setIsMarkedG}
                    setIsMarkedO={setIsMarkedO}

                    setCallIntervalID={setCallIntervalID}
                    audio={audio}
                />
            }

            <div className="bingo-container" style={ CARD_WIDTH * b.length > windowWidth ? {justifyContent: 'flex-start'} : {justifyContent: 'center'} }>
                { b.length > 0 && b.length === i.length && b.length === n.length && b.length === g.length && b.length === o.length && 
                    b.map( (bb, ind) => (
                        <BingoCard key={ind} index={ind} generateCardNumbers={generateCardNumbers} remove={remove}
                            drawnNumbers={drawnNumbers}
                            b={b} i={i} n={n} g={g} o={o}
                            isMarkedB={isMarkedB} isMarkedI={isMarkedI} isMarkedN={isMarkedN} isMarkedG={isMarkedG} isMarkedO={isMarkedO}
                            setIsMarkedB={setIsMarkedB} setIsMarkedI={setIsMarkedI} setIsMarkedN={setIsMarkedN} setIsMarkedG={setIsMarkedG} setIsMarkedO={setIsMarkedO}
                            callIntervalID={callIntervalID}
                            startGame={startGame}
                            seconds={seconds}
                        />
                    )
                ) }
            </div>

            <div className="text-align-center mt-3 mb-3">
                <button onClick={ () => handleAddCard() } disabled={startGame || b.length === MAX_NUM_CARDS}>[+] Add a card</button>
                <br /><br />
                <button onClick={handleStartGame} disabled={startGame}>Start Game</button>
            </div>
            
        </>
    );
}

const styles = {
    
};
 
export default Bingo;