import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const SearchAttachmentInDept = ({ setAttachSearchResult, setDocDetails, setTransHistory }) => {
    const [ searchType, setSearchType ] = useState("basic");
    const [ attachments, setAttachments ] = useState([]);
    const [ selectedAttachment, setSelectedAttachment ] = useState("");
    const [ refNum, setRefNum ] = useState("");

    useEffect( () => {
        fetch('/api/dts-work-history/get-attachments-made') //reuse
            .then( res => res.json() )
            .then( res => {
                if( res.attachments_made ) setAttachments(res.attachments_made);
            })
        ;
    }, [] );

    useEffect( () => {
        //clear ref num if basic search
        if( searchType === "basic" ) setRefNum("");
    }, [searchType] );

    const handleSearch = () => {
        fetch(`/api/dts-work-history/search-attachments?atid=${selectedAttachment}&rn=${refNum}`)
            .then( res => res.json() )
            .then( res => {
                if( res.searchResult ) setAttachSearchResult( res.searchResult );

                setDocDetails(null);
                setTransHistory(null);
            })
        ;
    }

    return (
        <fieldset className="width-50 ml-2">
            <legend>Search attachments in your department</legend>


            <div className="mt-3">
                <table className="width-100">
                    { searchType === "basic" &&
                        <tr>
                            <td className="width-30 text-align-right">
                                <label htmlFor="doc_type" className="mr-2" style={{width: 'unset', paddingRight: 'unset'}}>Attachments:</label>
                            </td>
                            <td className="width-70 text-align-left">
                                <select value={selectedAttachment} onChange={ (e) => setSelectedAttachment(e.target.value) }>
                                    <option value=""></option>
                                    { attachments.length > 0 && attachments.map( (a, i) => (
                                        <option key={i} value={a.attach_type_id}>{a.totalcount}</option>
                                    ) ) }
                                </select>
                            </td>
                        </tr>
                    }

                    { searchType === "advance" &&
                        <>
                            <tr>
                                <td className="width-40 text-align-right">
                                    <label htmlFor="doc_type" className="mr-2" style={{width: 'unset', paddingRight: 'unset'}}>Attachments:</label>
                                </td>
                                <td className="width-60 text-align-left">
                                    <select value={selectedAttachment} onChange={ (e) => setSelectedAttachment(e.target.value) }>
                                        <option value=""></option>
                                        { attachments.length > 0 && attachments.map( (a, i) => (
                                            <option key={i} value={a.attach_type_id}>{a.totalcount}</option>
                                        ) ) }
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td className="width-40 text-align-right pt-2">
                                    <label htmlFor="doc_type" className="mr-2" style={{width: 'unset', paddingRight: 'unset'}}>Reference Number:</label>
                                </td>
                                <td className="width-60 text-align-left pt-2">
                                    <input type="text" className="width-100" value={refNum} onChange={ (e) => setRefNum(e.target.value) } />
                                </td>
                            </tr>
                        </>
                    }
                </table>
            </div>

            <div className="mt-3 text-align-center">
                <button className="search mt-2" style={{width: '70%'}} onClick={handleSearch}>Search</button>
                <br /><br />
                <Link to="#" onClick={ () => setSearchType(searchType === "basic" ? "advance" : "basic") } className="font-size-1-2 bold">{ searchType === "basic" ? "Advance" : "Basic" } Search</Link>
            </div>
        </fieldset>
    );
}
 
export default SearchAttachmentInDept;