import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import DocDetailsForProcessor from "../components/DocDetailsForProcessor";

const DocumentsForReceiving = ({ forReceivingDocs, setCheckedDocs }) => {
    const [ docDetails, setDocDetails ] = useState(null);

    const [checkedAll, setCheckedAll] = useState(false);
    const [checked, setChecked] = useState( () => {
        let obj = {};

        for( let i = 0; i < forReceivingDocs.length; i++ ) {
            const name = "k" + forReceivingDocs[i].doc_id.replace("-", "_");
            obj = { ...obj, [name]: false };
        }

        return obj;
    });

    const getDocumentDetails = (doc_id) => {
        fetch(`/api/dts-receiving/get-doc-details/${doc_id}`)
            .then( res => res.json() )
            .then( res => {
                if( 'doc_details' in res ) {
                    setDocDetails(res.doc_details);
                }
            })
        ;
    }

    ////####on check/uncheck: update details
    // const handleOnCheckboxChange = (doc_id, isChecked) => {
    //     if( isChecked ) { //check
    //         if( checkedDocuments.length + 1 === 1 ) getDocumentDetails(doc_id); //get details if only one checkbox is selected
    //         else setDocDetails(null); //empty doc details if more than one is selected

    //         setCheckedDocuments([ ...checkedDocuments, doc_id ]); //add doc_id to checked documents list
    //     } else { //uncheck
    //         setCheckedDocuments( checkedDocuments => checkedDocuments.filter( cd => cd !== doc_id ) ); //remove doc_id from checked documents list
    //     }
    // }

    const toggleCheck = (inputName) => {
        setChecked((prevState) => {
            const newState = { ...prevState };
            newState[inputName] = !prevState[inputName];
            return newState;
        });

        setDocDetails(null); //remove details
    };

    const selectAll = (value) => {
        setCheckedAll(value);
        setChecked((prevState) => {
            const newState = { ...prevState };
            for (const inputName in newState) {
                newState[inputName] = value;
            }
            return newState;
        });

        setDocDetails(null); //remove details
    };

    useEffect( () => {
        let allChecked = true;
        let gotIntoForLoop = false;
        let checkedDocs = [];

        for (const inputName in checked) {
            if (checked[inputName] === false) allChecked = false;
            if (checked[inputName] === true) checkedDocs.push( inputName.replace("_", "-").substring(1) );

            gotIntoForLoop = true;
        }

        if (allChecked && gotIntoForLoop) setCheckedAll(true);
        else setCheckedAll(false);

        setCheckedDocs(checkedDocs);
    }, [checked] );

    return (
        <div>
            { forReceivingDocs.length === 0 &&
                <div className="text-align-center mb-4">
                    <span className="fst-italic">No available documents for receiving.</span>
                </div>
            }

            { forReceivingDocs.length > 0 &&
                <div style={s.container} className="mb-4">
                    <table style={s.table}>
                        <tr style={s.tr}>
                            <th style={{ width: '5%' }}><input type="checkbox" onChange={(e) => selectAll(e.target.checked)} checked={checkedAll} /></th>
                            <th style={{ width: '15%' }}>Reference #</th>
                            <th style={{ width: '50%' }}>Title</th>
                            <th style={{ width: '20%' }}>Forwarded By</th>
                            <th style={{ width: '10%' }}></th>
                        </tr>
                        { forReceivingDocs.map( (frd, i) => (
                            <tr key={i} style={ forReceivingDocs.length === i + 1 ? {...s.tr, borderBottom: 'none'} : s.tr }>
                                {/* <td><input type="checkbox" onChange={ (e) => handleOnCheckboxChange(frd.doc_id, e.target.checked) } /></td> */}
                                <td><input type="checkbox" onChange={() => toggleCheck("k" + frd.doc_id.replace("-", "_"))} checked={checked["k" + frd.doc_id.replace("-", "_")]} name={ "k" + frd.doc_id.replace("-", "_") } /></td>
                                <td>{frd.doc_id}</td>
                                <td>{frd.title}</td>
                                <td>{frd.dept_title}</td>
                                <td>
                                    <Link to="#" onClick={ () => getDocumentDetails(frd.doc_id) }>Details</Link>
                                </td>
                            </tr>
                        )) }
                        
                    </table>
                </div>
            }

            { docDetails && <DocDetailsForProcessor docDetails={docDetails} /> }
        </div>
    );
}

const s = { //styles
    container: {
        backgroundColor: 'rgb(234 248 255)',
        width: '100%',
        padding: '5px 10px',
        border: '1px solid #bbb',
        borderRadius: '10px'
    },
    table: {
        width: '100%',
    },
    tr: {
        borderBottom: '1px solid #bbb',
        lineHeight: '2rem'
    }
}

export default DocumentsForReceiving;