import { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';

import iconClose from "../images/icons/close.png";

const SendEmail = ({ warningID }) => {
    const [ sendEmailDetails, setSendEmailDetails ] = useState(null);
    const [ showModal, setShowModal ] = useState(false);
    const [ to, setTo ] = useState("");
    const [ from, setFrom ] = useState("");
    const [ subject, setSubject ] = useState("");
    const [ message, setMessage ] = useState("");

    useEffect( () => {
        if(sendEmailDetails) {
            setTo(sendEmailDetails.receiver);
            setFrom(sendEmailDetails.sender);
            setSubject(sendEmailDetails.default_subject);
            setMessage(sendEmailDetails.default_message);
        }
    }, [sendEmailDetails] );

    const handleShowSendEmailModal = () => {
        setShowModal(true);

        fetch(`/api/dts-admin/send-email/get-details/${warningID}`)
            .then( res => res.json() )
            .then( res => {
                if( 'send_email_details' in res && res.send_email_details ) {
                    setSendEmailDetails(res.send_email_details);
                }
            })
        ;
    }

    const handleSendEmail = () => {
        if( !window.confirm("You are about to send an e-mail. Do you wish to continue?") ) return; //exit

        const details = { to, from, subject, message };

        fetch(`/api/dts-admin/send-email/send`,
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(details)
            }).then( res => res.json() )
            .then( res => {
                if( 'success' in res && res.success ) { //successful send
                    setShowModal(false);
                } else alert("Something went wrong!");
            })
        ;
    }

    const modalSendEmail = () => {
        return (
            <Modal show={showModal} backdrop="static" keyboard={false} centered size="lg">
                <Modal.Body>
                    <div className="text-align-right">
                        <input type="image" src={iconClose} onClick={ () => setShowModal(false) } />
                    </div>

                    <fieldset className="mt-2" style={s.fieldset}>
                        <legend style={s.legend}>Send E-mail</legend>

                        { sendEmailDetails &&
                            <table style={s.table}>
                                <tr>
                                    <td style={s.tdLabel}>To:</td>
                                    <td style={s.tdInput}>
                                        <input value={to} onChange={ (e) => setTo(e.target.value) } type="text" className="width-100" disabled/>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={s.tdLabel}>From:</td>
                                    <td style={s.tdInput}>
                                        <input type="text" value={from} onChange={ (e) => setFrom(e.target.value) } className="width-100" disabled/>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={s.tdLabel}>Subject:</td>
                                    <td style={s.tdInput}>
                                        <input type="text" value={subject} onChange={ (e) => setSubject(e.target.value) } className="width-100"/>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={s.tdLabel}>Message:</td>
                                    <td style={s.tdInput}>
                                        <textarea className="width-100" rows="10" value={message} onChange={ (e) => setMessage(e.target.value) }></textarea>
                                    </td>
                                </tr>
                            </table>
                        }
                    </fieldset>

                    <div className="mt-3 mb-3 text-align-center">
                        <button className="ml-2" onClick={ handleSendEmail }>Send</button>
                        <button className="ml-2" onClick={ () => setShowModal(false) }>Cancel/Close</button>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }

    return (
        <>
            <button className="ml-2" onClick={ handleShowSendEmailModal }>Send E-mail</button>

            { modalSendEmail() }
        </>
    );
}

const s = {
    fieldset: {
        border: "1px solid rgb(201 201 201)",
        borderRadius: "8px",
        padding: "12px"
    },
    legend: {
        textAlign: "right",
        border: "1px solid rgb(201 201 201)",
        borderRadius: "2px",
        padding: "2px 4px"
    },
    table: {
        width: "100%"
    },
    tdLabel: {
        fontWeight: "bold",
        paddingRight: "4px",
        width: "12%",
        textAlign: "right",
        paddingTop: "6px"
    },
    tdInput: {
        width: "88%",
        paddingTop: "6px"
    }
};
 
export default SendEmail;