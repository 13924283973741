import { useState } from "react";

import DocumentDetails from "../components/DocumentDetails";
import FolderLayout from "../components/FolderLayout";
import Footer from "../components/Footer";
import Header from "../components/Header";
import SearchDocInDepartment from "../components/SearchDocInDepartment";
import SearchDocInDepartmentResults from "../components/SearchDocInDepartmentResults";
import SearchAttachmentInDept from "../components/SearchAttachmentInDept";
import SearchAttachmentResults from "../components/SearchAttachmentResults";
import WHTransactionHistory from "../components/WH_TransactionHistory";

const WorkHistory = ({ properties: p }) => {
    const [ docDetails, setDocDetails ] = useState(null);
    const [ transHistory, setTransHistory ] = useState(null);
    const [ didSearch, setDidSearch ] = useState(false);
    const [ showDocDetails, setShowDocDetails ] = useState(false);

    const [ advSearchResult, setAdvSearchResult ] = useState([]);
    const [ attachSearchResult, setAttachSearchResult ] = useState([]);

    return (
        <div className="container">
            <Header name={p.name} department={p.department} />

            <FolderLayout page="work-history">
                <div className="d-flex mt-2">
                    <SearchDocInDepartment setDocDetails={setDocDetails} setTransHistory={setTransHistory} setDidSearch={setDidSearch} setShowDocDetails={setShowDocDetails} setAdvSearchResult={setAdvSearchResult} setAttachSearchResult={setAttachSearchResult} />

                    <SearchAttachmentInDept setAttachSearchResult={setAttachSearchResult} setDocDetails={setDocDetails} setTransHistory={setTransHistory} />
                </div>

                { attachSearchResult.length === 0 && //default show
                    <SearchDocInDepartmentResults docDetails={docDetails} didSearch={didSearch} setShowDocDetails={setShowDocDetails} advSearchResult={advSearchResult} setDocDetails={setDocDetails} setTransHistory={setTransHistory} />
                }
                { attachSearchResult.length > 0 && //show only if length is > 0
                    <SearchAttachmentResults attachSearchResult={attachSearchResult} setDocDetails={setDocDetails} setShowDocDetails={setShowDocDetails} setTransHistory={setTransHistory} />
                }

                { docDetails && showDocDetails && <DocumentDetails docDetails={docDetails} /> }

                { transHistory && showDocDetails && <WHTransactionHistory transHistory={transHistory} /> }
            </FolderLayout>

            <Footer />
        </div>
    );
}
 
export default WorkHistory;