
const mon = new Array("January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December");
const day = new Array("Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday");

export const FormatCurrentDate = () => {
    const cur_comp_date = new Date();
    const cur_month = cur_comp_date.getMonth();
    const cur_date = cur_comp_date.getDate();
    const cur_year = cur_comp_date.getFullYear();
    const cur_day = cur_comp_date.getDay();
    
    return( day[cur_day] + ", " + mon[cur_month] + " " + cur_date + ", " + cur_year );
}

export const FormatDate = (date) => {
    const full_date = new Date(date);
    const new_month = full_date.getMonth();
    const new_date = full_date.getDate();
    const new_year = full_date.getFullYear();
    const new_day = full_date.getDay();

    return( day[new_day] + ", " + mon[new_month] + " " + new_date + ", " + new_year );
}
