import { useState, useEffect } from 'react';

import Header from "../components/Header";
import FolderLayout from "../components/FolderLayout";
import Footer from "../components/Footer";

import iconError from '../images/icons/error.png';
import iconSuccess from '../images/icons/success.png'

import '../css/dts-pages.css';

const EditProfile = ({ properties: p }) => {
    const [ search, setSearch ] = useState('');
    const [ searchResults, setSearchResults ] = useState([]);

    const [ departments, setDepartments ] = useState([]);
    const [ roles, setRoles ] = useState([]);

    const [ userid, setUserid ] = useState('');
    const [ lname, setLname ] = useState('');
    const [ fname, setFname ] = useState('');
    const [ mname, setMname ] = useState('');
    const [ gender, setGender ] = useState('');
    const [ username, setUsername ] = useState('');
    const [ department, setDepartment ] = useState('');
    const [ isDepartmentHead, setIsDepartmentHead ] = useState(false);
    const [ role, setRole ] = useState('');
    const [ roleCode, setRoleCode ] = useState('');
    const [ effstatus, setEffstatus ] = useState('');
    const [ createdDt, setCreatedDt ] = useState('');
    const [ createdBy, setCreatedBy ] = useState('');

    const [ isUsernameDisabled, setIsUsernameDisabled ] = useState(false);
    const [ isDeptHeadDisabled, setIsDeptHeadDisabled ] = useState(false);
    const [ disableAll, setDisableAll ] = useState(true);

    const [ isLoading, setIsLoading ] = useState(false);
    const [ errors, setErrors ] = useState([]);
    const [ successResult, setSuccessResult ] = useState(null);

    useEffect(() => {
        fetch('/api/get-departments-and-roles')
            .then( res => res.json() )
            .then( res => {
                if( 'departments' in res ) setDepartments(res.departments);
                if( 'roles' in res ) setRoles(res.roles);
            })
        ;
    }, []);

    const handleSearch = () => {
        clear();

        fetch(`/api/search-users?keywords=${search}`)
            .then( res => res.json() )
            .then( res => {
                if( 'results' in res && res.results ) {
                    setSearchResults(res.results);
                }
            })
        ;
    }

    const handleDepartmentChange = (value) => {
        if( value !== "" ) {
            fetch(`/api/get-department-supervisor/${value}`)
                .then( res => res.json() )
                .then( res => {
                    if( res.results ) { //department has supervisor
                        setIsDeptHeadDisabled(true);
                    } else { //department has no supervisor
                        setIsDeptHeadDisabled(false);
                    }
                })
            ;
        } else {
            setIsDepartmentHead(false);
        }

        setIsDepartmentHead(false);
        setDepartment(value);
    }

    const handleRoleChange = (value, text) => {
        //if messenger: disable checkbox and username input box
        if(text === 'Messenger') {
            setIsDepartmentHead(false);
            setIsDeptHeadDisabled(true);

            setUsername('');
            setIsUsernameDisabled(true);
        } else {
            handleDepartmentChange(department);
            //setIsDeptHeadDisabled(false);
            setIsUsernameDisabled(false);
        }
        
        //set role
        setRoleCode(value);
        setRole(text);
    }

    const handleShowUserInfo = (userid) => {
        fetch(`/api/get-user-info/${userid}`)
            .then( res => res.json() )
            .then( res => {
                setDisableAll(false);
                setUserid(res.userid);
                setLname(res.lastname);
                setFname(res.firstname);
                setMname(res.middlename);
                setGender(res.gender);
                setUsername(res.emailadd);
                setDepartment(res.dept_code);
                setIsDepartmentHead(res.is_head);
                setRole(res.role_descr);
                setRoleCode(res.role_code);
                setEffstatus(res.effstatus);
                setCreatedDt(res.created_dt);
                setCreatedBy(res.created_by);

                setIsDeptHeadDisabled( res.is_messenger );
                setIsUsernameDisabled( res.is_messenger );

                setSuccessResult(null);
                setErrors([]);
            })
        ;
    }

    const clear = () => {
        setDisableAll(true);
        setUserid('');
        setLname('');
        setFname('');
        setMname('');
        setGender('');
        setDepartment('');
        setIsDepartmentHead(false);
        setRoleCode('');
        setRole('');
        setUsername('');
        setEffstatus('');
        setCreatedDt('');
        setCreatedBy('');

        setSuccessResult(null);
        setErrors([]);
    }

    const handleUpdate = () => {
        if( lname, fname, gender, department, roleCode, role, username ) { //must have values
            setIsLoading(true);

            const userDetails = { lname, fname, mname, gender, department, roleCode, role, username, isDepartmentHead, effstatus };

            fetch(`/api/users/${userid}`,
                {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(userDetails)
                }).then( res => res.json() 
                ).then(res => {
                    if( Array.isArray(res) ) { //returned an array with error message
                        setErrors( res );
                        setSuccessResult(null);
                    } else { //no error, registered successfully
                        setSuccessResult(res);
                        setErrors([]);
                    }

                    setIsLoading(false);
                })
            ;
        } else {
            setErrors([{ err_msg: 'Fields marked with asterisk (*) are required.' }]);
        }
    }

    const handleDelete = () => {
        const confirmDelete = window.confirm(`Are you sure you want to delete the account of ${fname} ${lname}? \n\n You will not be able to undo this action.`);
        if( confirmDelete ) {
            setIsLoading(true);

            fetch(`/api/users/${userid}`, { method: 'DELETE' })
                .then( res => res.json() )
                .then( res => {
                    if( Array.isArray(res) ) { //returned an array with error message
                        setErrors( res );
                        setSuccessResult(null);
                    } else { //no error, registered successfully
                        handleSearch();
                    }

                    setIsLoading(false);
                })
            ;
        }
    }

    return (
        <div className="container">
            <Header name={p.name} department={p.department} />
            
            <FolderLayout page="edit-profile">
                <>
                    <div className="edit-profile">
                        <div className="search-and-results">
                            <fieldset className="search">
                                <legend>Search</legend>
                                <input type="text" value={search} onChange={ (e) => setSearch(e.target.value) } />
                                <button className="ml-2" onClick={handleSearch}>Search</button>
                            </fieldset>

                            <fieldset className="results mt-3">
                                <legend>Results</legend>
                                <div>
                                    { searchResults.length > 0 &&
                                        searchResults.map( (sr, i) => (
                                            <a key={i} className="names" onClick={ () => handleShowUserInfo(sr.userid) }>
                                                {sr.lastname}, {sr.firstname}
                                            </a>
                                        ) )
                                    }
                                </div>
                                
                            </fieldset>
                        </div>
                        
                        <div className="information ml-3">
                            <fieldset>
                                <legend>Information</legend>
                                
                                <div>
                                    <label for="lname">* Last Name</label>
                                    <input type="text" id="lname" value={lname} onChange={ (e) => setLname(e.target.value) } disabled={disableAll} required />
                                </div>
                                <div className="mt-3">
                                    <label for="fname">* First Name</label>
                                    <input type="text" id="fname" value={fname} onChange={ (e) => setFname(e.target.value) } disabled={disableAll} required />
                                </div>
                                <div className="mt-3">
                                    <label for="mname">Middle Name</label>
                                    <input type="text" id="mname" value={mname} onChange={ (e) => setMname(e.target.value) } disabled={disableAll} />
                                </div>
                                <div className="mt-3">
                                    <label>* Gender</label>
                                    <select value={gender} onChange={ (e) => setGender(e.target.value) } disabled={disableAll} required>
                                        <option value=""></option>
                                        <option value="M">Male</option>
                                        <option value="F">Female</option>
                                    </select>
                                </div>
                                <div className="mt-3">
                                    <label for="username">* Username/Email</label>
                                    <input type="text" id="username" value={username} onChange={ (e) => setUsername(e.target.value) } disabled={disableAll || isUsernameDisabled} required />
                                </div>
                                <div className="mt-3">
                                    <label for="department">* Department</label>
                                    <select value={department} onChange={ (e) => handleDepartmentChange(e.target.value) } disabled={disableAll} required>
                                        <option value=""></option>
                                        { departments.length > 0 && departments.map( (d, i) => (
                                            <option key={i} value={d.dept_code}>{d.dept_title}</option>
                                        ))}
                                    </select>

                                    <input type="checkbox" id="isDepartmentHead" className="ml-3" checked={isDepartmentHead} onChange={ (e) => setIsDepartmentHead(e.target.checked) } disabled={disableAll || isDeptHeadDisabled} />
                                    <label for="isDepartmentHead" className="adjust ml-1">Department Head</label>
                                </div>
                                <div className="mt-3">
                                    <label>* Role</label>
                                    <select value={roleCode} onChange={ (e) => handleRoleChange( e.target.value, e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text ) } disabled={disableAll} required>
                                        <option></option>
                                        { roles.length > 0 && roles.map( (r, i) => (
                                            <option key={i} value={r.role_code}>{r.descr}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="mt-3">
                                    <label style={ effstatus === "I" ? {color: 'red'} : {}}>* Effective Status</label>
                                    <select value={effstatus} onChange={ (e) => setEffstatus(e.target.value) } disabled={disableAll} required>
                                        <option value=""></option>
                                        <option value="A">Active</option>
                                        <option value="I">Inactive</option>
                                    </select>
                                </div>
                                <div className="mt-3">
                                    <label>Created Date</label>
                                    <span>{createdDt}</span>
                                </div>
                                <div className="mt-3">
                                    <label>Created By</label>
                                    <span>{createdBy}</span>
                                </div>

                                { errors.length > 0 &&
                                    <div className="error">
                                        <div className="mb-2">
                                            <img src={iconError} alt="Error" />
                                            <h3 className="ml-2">Error</h3>
                                        </div>
                                        { errors.map( (e) => (
                                            <span key={e.err_msg}>{e.err_msg}</span>
                                        ) ) }
                                    </div>
                                }

                                { successResult &&
                                    <div className="success">
                                        <img src={iconSuccess} alt="Success" />
                                        <h3 className="ml-2">{successResult.msg}</h3>
                                    </div>
                                }
                            </fieldset>
                            <div className="buttons">
                                <button className="ml-2" onClick={handleUpdate} disabled={disableAll}>Update</button>
                                <button className="ml-2" onClick={handleDelete} disabled={disableAll}>Delete</button>
                                <button className="ml-2" onClick={clear} disabled={disableAll}>Cancel</button>
                            </div>
                            
                        </div>
                        
                    </div>
                </>
            </FolderLayout>
            
            <Footer />
        </div>
    );
}
 
export default EditProfile;