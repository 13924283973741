import { Link } from "react-router-dom";

import bar from '../images/bar.gif';
import clientLogo from '../images/clientLogo.png';
import dtsLogo from '../images/dtsLogo_2.jpg';
import topBG from '../images/topBG.gif';

import { FormatCurrentDate }  from './FormatDate';

const Header = ({ name, department }) => {
    return(
        <div className="header">
            <div className="logo-container" style={styles.logoContainer}>
                <img className="dts-logo" src={dtsLogo} alt="DTS Logo" />
                {/* <img className="client-logo" src={clientLogo} alt="Logo" /> */}
            </div>
            <div className="header-bar" style={styles.headerBar}>
                { name && 
                    <span className="welcome-text">{ 'Welcome ' + name + ', ' }
                        <Link to="/signout" className="link3">Sign Out</Link>
                    </span>
                }
                { !name && <span className="welcome-text">Welcome to the Document Tracking System</span> }
                
                { department && <span>{department}</span> }
                
                <span className="show-date"><FormatCurrentDate /></span>
            </div>
        </div>
    );
}

const styles = {
    logoContainer: {
        background: `url(${topBG}) repeat-x`
    },
    headerBar: {
        background: `url(${bar}) repeat-x`
    }
}

export default Header;