
const WH_TransactionHistory = ({ transHistory }) => {
    return (
        <fieldset className="mt-3">
            <legend>Transaction History</legend>

            <div style={s.container}>
                <table style={s.table}>
                    <tr style={s.tr}>
                        <th style={{ width: '20%' }}>Acted Date/Time</th>
                        <th style={{ width: '25%' }}>Action Taken</th>
                        <th style={{ width: '25%' }}>Acted By</th>
                        <th style={{ width: '30%' }}>Remarks</th>
                    </tr>
                    { transHistory.map( (th, i) => (
                        <tr key={i} style={ transHistory.length === i + 1 ? {...s.tr, borderBottom: 'none'} : s.tr }>
                            <td>{th.acted_dt}</td>
                            <td>{th.action_name}</td>
                            <td>{th.full_name}</td>
                            <td>{th.remarks}</td>
                        </tr>
                    )) }
                    
                </table>
            </div>
        </fieldset>
    );
}

const s = { //styles
    container: {
        backgroundColor: 'rgb(234 248 255)',
        width: '100%',
        padding: '5px 10px',
        border: '1px solid #bbb',
        borderRadius: '10px'
    },
    table: {
        width: '100%',
    },
    tr: {
        borderBottom: '1px solid #bbb',
        lineHeight: '2rem'
    },
    tdLeft: {
        textAlign: 'right',
        paddingRight: '6px',
        width: '25%'
    }
}
 
export default WH_TransactionHistory;