import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

import iconClose from "../images/icons/close.png";
import iconSuccess from '../images/icons/success.png';

const SetArchiveOrLost = ({ type, checkedDocs, modalBtnText, modalBtnClasses, label, saveBtnText, setRefreshDocs }) => {
    const [ showModal, setShowModal ] = useState(false);
    const [ showModalSuccess, setShowModalSuccess ] = useState("");
    const [ remarks, setRemarks ] = useState("");

    const handleSendModal = () => {
        if( checkedDocs.length > 0 ) {
            setShowModal(true);
        } else alert("Please select a document.");
    }

    const handleSave = () => {
        if( checkedDocs.length > 0 ) {
            let apiPath = "", msg = "";
            if( type === "archive" ) {
                apiPath = "/api/dts-action/archive-document/";
                msg = "You have successfully archived the document(s).";
            } else if( type === "lost" ) {
                apiPath = "/api/dts-action/mark-as-lost-document/";
                msg = "You have successfully marked as lost.";
            }

            for( let i = 0; i < checkedDocs.length; i++ ) {
                fetch(`${apiPath}${checkedDocs[i]}`,
                    {
                        method: 'PUT',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ remarks })
                    }).then( res => res.json() )
                    .then( res => {
                        if( 'msg' in res ) {
                            setShowModalSuccess(msg);
        
                            setShowModal(false);

                            setRefreshDocs( (t) => !t );
                        }
                    })
                ;
            }
        } else alert("Please select a document.");
    }

    const modal = () => {
        return (
            <Modal show={showModal} onHide={ () => setShowModal(false) } backdrop="static" keyboard={false} centered>
                <Modal.Body>
                    <div className="text-align-right">
                        <input type="image" src={iconClose} onClick={ () => setShowModal(false) } />
                    </div>

                    <div className="d-flex justify-content-end align-items-center mt-3">
                        <label className="text-align-right">{label}</label>
                        <textarea value={remarks} onChange={ (e) => setRemarks(e.target.value) } rows="4" className="width-80 ml-2"></textarea>
                    </div>

                    <div className="d-flex justify-content-end align-items-center mt-3 mb-4">
                        <label></label>
                        <div className="width-80 ml-2">
                            <button onClick={handleSave}>{saveBtnText}</button>
                            <button className="ml-2" onClick={ () => setShowModal(false) }>Cancel</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    };

    const modalSuccess = () => {
        return (
            <Modal show={showModalSuccess} onHide={ () => handleCloseSuccessModal() } backdrop="static" keyboard={false} centered>
                <Modal.Body>
                    <div className="text-align-right">
                        <input type="image" src={iconClose} onClick={ () => handleCloseSuccessModal() } />
                    </div>
                    <div className="mt-4 mb-3">
                        <div className="mb-2 text-align-center">
                            <img src={iconSuccess} alt="Success" />
                            <span className="ml-2 bold" style={{ color: 'rgb(0, 72, 255)', fontSize: '1.2rem' }}>{showModalSuccess}</span>
                        </div>
                    </div>
                    <div className="mb-3 text-align-center">
                        <button className="ml-2" onClick={ () => handleCloseSuccessModal() }>Okay</button>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }

    const handleCloseSuccessModal = () => {
        setRemarks("");
        setShowModalSuccess("")
    }

    return (
        <>
            <button onClick={ handleSendModal } className={modalBtnClasses}>{modalBtnText}</button>

            { modal() }

            { modalSuccess() }
        </>
    );
}

export default SetArchiveOrLost;