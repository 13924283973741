import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";

const SearchDocInDepartment = ({ setDocDetails, setTransHistory, setDidSearch, setShowDocDetails, setAdvSearchResult, setAttachSearchResult }) => {
    const [ searchSubType, setSearchSubType ] = useState("basic");

    const [ docID, setDocID ] = useState("");

    const [ docType, setDocType ] = useState('');
    const [ documentTypes, setDocumentTypes ] = useState([]);

    const [ startDate, setStartDate ] = useState('');
    const [ endDate, setEndDate ] = useState('');
    const [ descr, setDescr ] = useState('');

    useEffect( () => {
        fetch('/api/dts-document-tracking') //reuse
            .then( res => res.json() )
            .then( res => {
                if( res.document_types ) setDocumentTypes(res.document_types);
            })
        ;
    }, [] );

    const handleSearch = () => {
        if( searchSubType === "basic" ) {
            if(docID === "") return alert("Please enter a Document ID."); //exit with message

            fetch(`/api/dts-work-history/basic-search/${docID}`)
                .then( res => res.json() )
                .then( res => {
                    if( res.docDetails ) setDocDetails(res.docDetails);
                    if( res.transHistory ) setTransHistory(res.transHistory);
                    
                    setDidSearch(true);
                    setShowDocDetails(false);

                    setAdvSearchResult([]);
                })
            ;
        } else if( searchSubType === "advance" ) {
            fetch(`/api/dts-work-history/advance-search?dt=${docType}&sd=${startDate}&ed=${endDate}&descr=${descr}`)
                .then( res => res.json() )
                .then( res => {
                    if( res.searchResult ) {
                        setAdvSearchResult(res.searchResult);
                        
                        //set to empty
                        setDocDetails(null);
                        setTransHistory(null);

                        setDidSearch(false); //should be setDidBasicSearch
                    }
                })
            ;
        }

        setAttachSearchResult([]);
    }

    return (
        <fieldset className="width-50 mr-2">
            <legend>Search documents that were processed in your department</legend>

            { searchSubType === "basic" &&
                <div className="mt-3 text-align-center">
                    <label htmlFor="doc_id" className="mr-2" style={{width: 'unset', paddingRight: 'unset'}}>Document ID:</label>
                    <input type="text" id="doc_id" value={docID} onChange={ (e) => setDocID(e.target.value) } />
                </div>
            }

            { searchSubType === "advance" &&
                <div className="mb-3">
                    <table className="width-100">
                        <tr>
                            <td className="width-40 text-align-right">
                                <label htmlFor="doc_type" className="mr-2" style={{width: 'unset', paddingRight: 'unset'}}>Document Type:</label>
                            </td>
                            <td className="width-60 text-align-left">
                                <select value={docType} onChange={ (e) => setDocType(e.target.value) } style={{width: '100%'}}>
                                    <option value=""></option>
                                    { documentTypes.length > 0 && documentTypes.map( (dt, i) => (
                                        <option key={i} value={dt.doc_type_id}>{dt.descr}</option>
                                    ) ) }
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td className="width-40 text-align-right pt-2">
                                <label className="mr-2" style={{width: 'unset', paddingRight: 'unset'}}>Date Received:</label>
                            </td>
                            <td className="width-60 text-align-left pt-2 d-flex align-items-center">
                                <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} showPopperArrow={false} />
                                <span className="ml-2 mr-2">to</span>
                                <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} showPopperArrow={false} />
                            </td>
                        </tr>
                        <tr>
                            <td className="width-40 text-align-right pt-2">
                                <label className="mr-2" style={{width: 'unset', paddingRight: 'unset'}}>Description:</label>
                            </td>
                            <td className="width-60 text-align-left pt-2">
                                <textarea rows="2" value={descr} onChange={ (e) => setDescr(e.target.value) } style={{width: '100%'}}></textarea>
                            </td>
                        </tr>
                    </table>
                    
                </div>
            }

            <div className="mt-3 text-align-center">
                <button className="search mt-2" style={{width: '70%'}} onClick={handleSearch}>Search</button>
                <br /><br />
                <Link to="#" onClick={ () => setSearchSubType(searchSubType === "basic" ? "advance" : "basic") } className="font-size-1-2 bold">{ searchSubType === "basic" ? "Advance" : "Basic" } Search</Link>
            </div>

            
        </fieldset>
    );
}
 
export default SearchDocInDepartment;