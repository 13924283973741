import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ForwardAuthenticated = ({ children, isAuthenticated, reAuthToggle, setReAuthToggle, path }) => {
    const navigate = useNavigate();

    useEffect( () => setReAuthToggle( !reAuthToggle ), [] ); //toggle to re-authenticate

    if( isAuthenticated === true ) navigate(path);

    return <>{children}</>;
}
 
export default ForwardAuthenticated;