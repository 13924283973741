import { useState } from "react";
import { Link } from "react-router-dom";

const TransactionHistory = ({ transHistory }) => {
    const [ outDetails, setOutDetails ] = useState([]);
    const [ inDetails, setInDetails ] = useState([]);

    const handleDetailsClick = (status, doc_id, deptcd, doc_move_id) => {
        if( status === "Released" ) {
            fetch(`/api/dts-document-tracking/transaction-history/details/out/${doc_id}/${deptcd}/${doc_move_id}`)
                .then( res => res.json() )
                .then( res => {
                    if( res.outDetails ) setOutDetails(res.outDetails);

                    setInDetails([]); //empty to hide in details
                })
            ;
        } else if( status === "Received" ) {
            fetch(`/api/dts-document-tracking/transaction-history/details/in/${doc_id}/${deptcd}`)
                .then( res => res.json() )
                .then( res => {
                    if( res.inDetails ) setInDetails(res.inDetails);

                    setOutDetails([]); //empty to hide in details
                })
            ;
        }
    }

    return (
        <fieldset className="mt-3">
            <legend>Transaction History</legend>

            <div style={s.container}>
                <table style={s.table}>
                    <tr style={s.tr}>
                        <th style={{ width: '30%' }}>Date</th>
                        <th style={{ width: '15%' }}>Status</th>
                        <th style={{ width: '20%' }}>Department</th>
                        <th style={{ width: '25%' }}>Acted By</th>
                        <th style={{ width: '10%' }}>More</th>
                    </tr>
                    { transHistory.map( (th, i) => (
                        <tr key={i} style={ transHistory.length === i + 1 ? {...s.tr, borderBottom: 'none'} : s.tr }>
                            <td>{th.date}</td>
                            <td>{th.action}</td>
                            <td>{th.dept_title}</td>
                            <td>{th.full_name}</td>
                            <td>
                                { th.details &&
                                    <Link to="#" onClick={ () => handleDetailsClick(th.action, th.doc_id, th.deptcd, th.doc_move_id) }>{th.details}</Link>
                                }
                            </td>
                        </tr>
                    )) }
                    
                </table>
            </div>

            { outDetails.length > 0 &&
                <fieldset className="mt-3">
                    <legend>Details</legend>

                    <div style={s.container}>
                        <table style={s.table}>
                            <tr style={s.tr}>
                                <th style={{ width: '30%' }}>Forwarded To</th>
                                <th style={{ width: '30%' }}>Received By</th>
                                <th style={{ width: '40%' }}>Sender's Remarks</th>
                            </tr>
                            { outDetails.map( (od, i) => (
                                <tr key={i} style={ outDetails.length === i + 1 ? {...s.tr, borderBottom: 'none'} : s.tr }>
                                    <td>{od.dept_title}</td>
                                    <td>{od.full_name}</td>
                                    <td>{od.remarks}</td>
                                </tr>
                            )) }
                        </table>
                    </div>
                </fieldset>
            }

            { inDetails.length > 0 &&
                <fieldset className="mt-3">
                    <legend>Details</legend>

                    <div style={s.container}>
                        <table style={s.table}>
                            <tr style={s.tr}>
                                <th style={{ width: '30%' }}>Acted Date/Time</th>
                                <th style={{ width: '15%' }}>Action Taken</th>
                                <th style={{ width: '30%' }}>Acted By</th>
                                <th style={{ width: '25%' }}>Remarks</th>
                            </tr>
                            { inDetails.map( (id, i) => (
                                <tr key={i} style={ inDetails.length === i + 1 ? {...s.tr, borderBottom: 'none'} : s.tr }>
                                    <td>{id.acted_dt}</td>
                                    <td>{id.action_name}</td>
                                    <td>{id.full_name}</td>
                                    <td>{id.remarks}</td>
                                </tr>
                            )) }
                        </table>
                    </div>
                </fieldset>
            }
            
        </fieldset>
    );
}

const s = { //styles
    container: {
        backgroundColor: 'rgb(234 248 255)',
        width: '100%',
        padding: '5px 10px',
        border: '1px solid #bbb',
        borderRadius: '10px'
    },
    table: {
        width: '100%',
    },
    tr: {
        borderBottom: '1px solid #bbb',
        lineHeight: '2rem'
    },
    tdLeft: {
        textAlign: 'right',
        paddingRight: '6px',
        width: '25%'
    }
}
 
export default TransactionHistory;