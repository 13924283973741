import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";

import DocAdvSearchResults from "../components/DocAdvSearchResults";
import DocumentDetails from "../components/DocumentDetails";
import FolderLayout from "../components/FolderLayout";
import Footer from "../components/Footer";
import Header from "../components/Header";
import TransactionHistory from "../components/TransactionHistory";

import "react-datepicker/dist/react-datepicker.css";

const DocumentTracking = ({ properties: p }) => {
    const [ searchType, setSearchType ] = useState("scanner");
    const [ searchSubType, setSearchSubType ] = useState("basic");

    const [ startDate, setStartDate ] = useState('');
    const [ endDate, setEndDate ] = useState('');
    const [ descr, setDescr ] = useState('');

    const [ docType, setDocType ] = useState('');
    const [ documentTypes, setDocumentTypes ] = useState([]);

    const [ docID, setDocID ] = useState();
    const [ docDetails, setDocDetails ] = useState(null);
    const [ transHistory, setTransHistory ] = useState(null);

    const [ advSearchResult, setAdvSearchResult ] = useState([]);

    useEffect( () => {
        fetch('/api/dts-document-tracking') //reuse
            .then( res => res.json() )
            .then( res => {
                if( res.document_types ) setDocumentTypes(res.document_types);
            })
        ;
    }, [] );

    const handleSearch = () => {
        if( searchType === "scanner" || ( searchType === "manual" && searchSubType === "basic" ) ) {
            basicSearch(docID);
        } else if( searchType === "manual" && searchSubType === "advance" ) {
            fetch(`/api/dts-document-tracking/advance-search?dt=${docType}&sd=${startDate}&ed=${endDate}&descr=${descr}`)
                .then( res => res.json() )
                .then( res => {
                    if( res.searchResult ) {
                        setAdvSearchResult(res.searchResult);

                        //set to empty
                        setDocDetails(null);
                        setTransHistory(null);
                    }
                })
            ;
        }
    }

    const basicSearch = (doc_id) =>{
        fetch(`/api/dts-document-tracking/basic-search/${doc_id}`)
            .then( res => res.json() )
            .then( res => {
                if( res.docDetails ) setDocDetails(res.docDetails);
                if( res.transHistory ) setTransHistory(res.transHistory);

                setAdvSearchResult([]); //set to empty
            })
        ;
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    }

    return (
        <div className="container">
            <Header name={p.name} department={p.department} />
                <FolderLayout page="doc-track">
                    <div className="doc-track">
                        <fieldset className="pb-4">
                            <legend>Search Document</legend>

                            <div className="d-flex flex-column">
                                <div className="for-radio text-align-center">
                                    <input type="radio" name="search_doc" id="qr_code_scanner" value="scanner" checked={searchType === "scanner"} onClick={ () => setSearchType('scanner') } />
                                    <label for="qr_code_scanner">QR Code Scanner</label>

                                    <input type="radio" name="search_doc" id="manual_search" className="ml-4" value="manual" checked={searchType === "manual"} onClick={ () => setSearchType('manual') } />
                                    <label for="manual_search">Manual Search</label>
                                </div>

                                { (searchType === "scanner" || (searchType === "manual" && searchSubType === "basic" )) &&
                                    <div className="mt-3 text-align-center">
                                        <label htmlFor="doc_id" className="mr-2">Document ID:</label>
                                        <input type="text" id="doc_id" value={docID} onChange={ (e) => setDocID(e.target.value) } onKeyDown={handleKeyDown} autoFocus />
                                    </div>
                                }

                                { searchType === "manual" &&
                                    <div className="mt-3 text-align-center">
                                        { searchSubType === "advance" &&
                                            <div className="mb-3">
                                                <table className="width-100">
                                                    <tr>
                                                        <td className="width-40 text-align-right">
                                                            <label htmlFor="doc_type" className="mr-2">Document Type:</label>
                                                        </td>
                                                        <td className="width-60 text-align-left">
                                                            <select value={docType} onChange={ (e) => setDocType(e.target.value) }>
                                                                <option value=""></option>
                                                                { documentTypes.length > 0 && documentTypes.map( (dt, i) => (
                                                                    <option key={i} value={dt.doc_type_id}>{dt.descr}</option>
                                                                ) ) }
                                                            </select>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="width-40 text-align-right pt-2">
                                                            <label className="mr-2">Date Received:</label>
                                                        </td>
                                                        <td className="width-60 text-align-left pt-2 d-flex align-items-center">
                                                            <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} showPopperArrow={false} />
                                                            <span className="ml-2 mr-2">to</span>
                                                            <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} showPopperArrow={false} />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="width-40 text-align-right pt-2">
                                                            <label className="mr-2">Description:</label>
                                                        </td>
                                                        <td className="width-60 text-align-left pt-2">
                                                            <textarea rows="2" value={descr} onChange={ (e) => setDescr(e.target.value) }></textarea>
                                                        </td>
                                                    </tr>
                                                </table>
                                                
                                            </div>
                                        }
                                        <Link to="#" onClick={ () => setSearchSubType(searchSubType === "basic" ? "advance" : "basic") } className="font-size-1-2 bold">{ searchSubType === "basic" ? "Advance" : "Basic" } Search</Link>
                                        <br />
                                        <button className="search mt-4" onClick={handleSearch}>Search</button>
                                    </div>
                                }
                            </div>
                        </fieldset>

                        { ( advSearchResult.length > 0 || !docDetails ) &&
                            <DocAdvSearchResults advSearchResult={advSearchResult} basicSearch={basicSearch} />
                        }

                        { docDetails && <DocumentDetails docDetails={docDetails} /> }

                        { transHistory && <TransactionHistory transHistory={transHistory} /> }
                    </div>
                </FolderLayout>
            <Footer />
        </div>
    );
}
 
export default DocumentTracking;