import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';

import FolderLayout from "../components/FolderLayout";
import Footer from "../components/Footer";
import Header from "../components/Header";
import ReceivedDocuments from "../components/ReceivedDocuments";
import SetArchiveOrLost from "../components/SetArchiveOrLost";

import iconClose from "../images/icons/close.png";
import iconSuccess from '../images/icons/success.png';
import iconRefresh from '../images/icons/refresh.png';

import iconMemoRed from '../images/icons/memoRed.png';
import iconNoWarnBlue from '../images/icons/noWarnBlue.png';
import iconPemOrange from '../images/icons/pemOrange.png';
import iconWarnYellow from '../images/icons/warnYellow.png';

const Action = ({ properties: p }) => {
    const [ triggerRefreshToggle, setTriggerRefreshToggle ] = useState(false);
    const [ receivedDocuments, setReceivedDocuments ] = useState([]);

    const [ modalDepartmentCode, setModalDepartmentCode ] = useState('');
    const [ departments, setDepartments ] = useState([]);
    const [ names, setNames ] = useState([]);
    const [ messengers, setMessengers ] = useState([]);
    const [ messengerCode, setMessengerCode ] = useState('');
    const [ remarks, setRemarks ] = useState('');

    const [showModal, setShowModal] = useState(false);
    const [showModalSuccess, setShowModalSuccess] = useState(false);

    const [ checkedDocs, setCheckedDocs ] = useState([]);

    useEffect( () => {
        fetch('/api/dts-action')
            .then( res => res.json() )
            .then( res => {
                if( 'received_documents' in res ) { //received docs are available
                    setReceivedDocuments(res.received_documents);
                }

                if( 'departments' in res ) setDepartments( res.departments );
            })
        ;
    }, [triggerRefreshToggle] );

    const handleModalDepartmentChange = (value) => {
        fetch(`/api/dts-main-document/get-list-of-messengers/${value}`)
            .then( res => res.json() )
            .then( res => {
                if( res.names ) setMessengers(res.names);
                else setMessengers([]);
            })
        ;

        setModalDepartmentCode(value);
    }

    const modal = () => {
        return (
            <Modal show={showModal} onHide={ () => setShowModal(false) } backdrop="static" keyboard={false} centered>
                <Modal.Body>
                    <div className="text-align-right">
                        <input type="image" src={iconClose} onClick={ () => setShowModal(false) } />
                    </div>

                    <div className="d-flex justify-content-end align-items-center mt-3">
                        <label className="text-align-right">* Destination</label>
                        <select value={modalDepartmentCode} onChange={ (e) => handleModalDepartmentChange(e.target.value) } className="width-80 ml-2">
                            <option value=""></option>
                            { departments.length > 0 && departments.map( (d, i) => (
                                <option key={i} value={d.dept_code}>{d.dept_title}</option>
                            ) ) }
                        </select>
                    </div>
                    <div className="d-flex justify-content-end align-items-center mt-3">
                        <label className="text-align-right">* Messenger</label>
                        <select value={messengerCode} onChange={ (e) => setMessengerCode(e.target.value) } className="width-80 ml-2">
                            <option value=""></option>
                            { messengers.length > 0 && messengers.map( (n, i) => (
                                <option key={i} value={n.userid}>{n.lastname}, {n.firstname}</option>
                            ) ) }
                        </select>
                    </div>
                    <div className="d-flex justify-content-end align-items-center mt-3">
                        <label className="text-align-right">* Remarks</label>
                        <textarea value={remarks} onChange={ (e) => setRemarks(e.target.value) } rows="4" className="width-80 ml-2"></textarea>
                    </div>

                    <div className="d-flex justify-content-end align-items-center mt-3 mb-4">
                        <label></label>
                        <div className="width-80 ml-2">
                            <button onClick={handleSendDocument}>Send</button>
                            <button className="ml-2" onClick={ () => setShowModal(false) }>Cancel</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    };

    const modalSuccess = () => {
        return (
            <Modal show={showModalSuccess} onHide={ () => handleCloseSuccessModal(false) } backdrop="static" keyboard={false} centered>
                <Modal.Body>
                    <div className="text-align-right">
                        <input type="image" src={iconClose} onClick={ () => handleCloseSuccessModal(false) } />
                    </div>
                    <div className="mt-4 mb-3">
                        <div className="mb-2 text-align-center">
                            <img src={iconSuccess} alt="Success" />
                            <span className="ml-2 bold" style={{ color: 'rgb(0, 72, 255)', fontSize: '1.2rem' }}>Successfully forwarded the document</span>
                        </div>
                    </div>
                    <div className="mb-3 text-align-center">
                        <button className="ml-2" onClick={ () => handleCloseSuccessModal(false) }>Okay</button>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }

    const handleCloseSuccessModal = () => {
        clearAll();
        setShowModalSuccess(false)
    }

    const clearAll = () => {
        setModalDepartmentCode('');
        setNames([]);
        setMessengers([]);
        setMessengerCode('');
        setRemarks('');
    }

    const handleSendModal = () => {
        if( checkedDocs.length > 0 ) {
            setShowModal(true);
        } else alert("Please select a document.");
    }

    const handleSendDocument = () => {
        if( checkedDocs.length > 0 ) {
            const sendDetails = {
                receiver_deptcd: modalDepartmentCode,
                messengerCode,
                remarks
            };

            for( let i = 0; i < checkedDocs.length; i++ ) {
                fetch(`/api/dts-action/forward-document/${checkedDocs[i]}`,
                    {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(sendDetails)
                    }).then( res => res.json() )
                    .then( res => {
                        if( 'msg' in res ) {
                            setShowModalSuccess(true);
        
                            setShowModal(false);

                            setTriggerRefreshToggle(!triggerRefreshToggle);
                        }
                    })
                ;
            }
        } else alert("Please select a document.");
    }

    const [showModalReturn, setShowModalReturn] = useState(false);
    const [ senderDept, setSenderDept ] = useState("");
    const [ messengerCodeReturn, setMessengerCodeReturn ] = useState('');
    const [ returnPhone, setReturnPhone ] = useState('');
    const [ returnReason, setReturnReason ] = useState('');
    const [ senderDeptCode, setSenderDeptCode ] = useState("");
    
    const modalReturn = () => {
        return (
            <Modal show={showModalReturn} onHide={ () => setShowModalReturn(false) } backdrop="static" keyboard={false} centered>
                <Modal.Body>
                    <div className="text-align-right">
                        <input type="image" src={iconClose} onClick={ () => setShowModalReturn(false) } />
                    </div>

                    <div className="d-flex justify-content-end align-items-center mt-3">
                        <label className="text-align-right">Destination</label>
                        <input type="text" className="width-60 ml-2" value={senderDept} disabled />
                    </div>

                    <div className="d-flex justify-content-end align-items-center mt-3">
                        <label className="text-align-right">Messenger</label>
                        <select value={messengerCodeReturn} onChange={ (e) => setMessengerCodeReturn(e.target.value) } className="width-60 ml-2">
                            <option value=""></option>
                            { messengers.length > 0 && messengers.map( (n, i) => (
                                <option key={i} value={n.userid}>{n.lastname}, {n.firstname}</option>
                            ) ) }
                        </select>
                    </div>

                    <div className="d-flex justify-content-end align-items-center mt-3">
                        <label className="text-align-right">Informed Thru Phone Call</label>
                        <input type="text" className="width-60 ml-2" value={returnPhone} onChange={ (e) => setReturnPhone(e.target.value) } />
                    </div>


                    <div className="d-flex justify-content-end align-items-center mt-3">
                        <label className="text-align-right">Reason <br /> Lacking Documents</label>
                        <textarea rows="4" className="width-60 ml-2" value={returnReason} onChange={ (e) => setReturnReason(e.target.value) }></textarea>
                    </div>

                    <div className="d-flex justify-content-end align-items-center mt-3 mb-4">
                        <label></label>
                        <div className="width-60 ml-2">
                            <button onClick={handleExecuteReturn}>Return</button>
                            <button className="ml-2" onClick={ () => setShowModalReturn(false) }>Cancel</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    };

    const handleReturn = () => {
        let sender_deptcd = "";

        if( checkedDocs.length === 0 ) alert("Please select a document to return.");
        else if( checkedDocs.length > 1 ) alert("Cannot return multiple documents.");
        else if( checkedDocs.length === 1 ) {
            for( let i = 0; i < receivedDocuments.length; i++ ) {
                if( receivedDocuments[i].doc_id === checkedDocs[0] ) {
                    sender_deptcd = receivedDocuments[i].sender_deptcd;
                    setSenderDeptCode(sender_deptcd);
                    setSenderDept( receivedDocuments[i].dept_title );
                }
            }

            fetch(`/api/dts-main-document/get-list-of-messengers/${sender_deptcd}`)
                .then( res => res.json() )
                .then( res => {
                    if( res.names ) setMessengers(res.names);
                    else setMessengers([]);
                })
            ;

            setShowModalReturn(true);
        }
    }

    const handleExecuteReturn = () => {
        if( messengerCodeReturn !== "" ) {
            fetch(`/api/dts-receiving/return/${checkedDocs[0]}`, //re-use '/api/dts-receiving/return', just add {from: "action"} in body
                {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ senderDeptCode, messengerCode: messengerCodeReturn, remarks: returnPhone + " | " + returnReason, reason: returnReason, from: "action" })
                }).then( res => res.json() )
                .then( res => {
                    if( 'msg' in res ) {
                        setShowModalSuccess(`You have successfully returned the document to ${senderDept}.`);
                        setShowModalReturn(false);
                        setTriggerRefreshToggle( !triggerRefreshToggle );
                    }
                })
            ;
        } else alert("Please select a messenger.");
    }

    return (
        <div className="container">
            <Header name={p.name} department={p.department} />

            <FolderLayout page="action">
                <div className="action mt-2">
                    <fieldset className="d-flex justify-content-end align-items-center">
                        <img src={iconRefresh} alt="Refresh" />
                        <Link to="#" onClick={ () => setTriggerRefreshToggle( !triggerRefreshToggle ) } className="bold ml-2">Refresh for new incoming documents</Link>
                    </fieldset>

                    <fieldset className="mt-3">
                        <legend>Status Legend</legend>

                        <div className="d-flex justify-content-evenly">
                            <div>
                                <img src={iconNoWarnBlue} alt="On Schedule" />
                                <span className="ml-2">- On Schedule</span>
                            </div>
                            <div>
                                <img src={iconWarnYellow} alt="Warning" />
                                <span className="ml-2">- Warning</span>
                            </div>
                            <div>
                                <img src={iconMemoRed} alt="For Memo" />
                                <span className="ml-2">- For Memo</span>
                            </div>
                            <div>
                                <img src={iconPemOrange} alt="Printed/E-mailed Memo" />
                                <span className="ml-2">- Printed/E-mailed Memo</span>
                            </div>
                        </div>
                    </fieldset>

                    <fieldset className="mt-3">
                        <legend>Received Documents</legend>

                        <ReceivedDocuments receivedDocuments={receivedDocuments} statusIcons={{iconMemoRed, iconNoWarnBlue, iconPemOrange, iconWarnYellow}} setCheckedDocs={setCheckedDocs} triggerRefreshToggle={triggerRefreshToggle} />
                    </fieldset>

                    <fieldset className="mt-3 text-align-center">
                        <legend>Controls</legend>

                        <button className="ml-1 mr-1" onClick={ handleSendModal }>Send/Forward</button>
                        <SetArchiveOrLost type="archive" checkedDocs={checkedDocs} modalBtnText="Archive Document" modalBtnClasses="ml-1 mr-1" label="Remarks:" saveBtnText="Archive" setRefreshDocs={setTriggerRefreshToggle} />
                        <SetArchiveOrLost type="lost" checkedDocs={checkedDocs} modalBtnText="Mark as Lost" modalBtnClasses="ml-1 mr-1" label="Remarks:" saveBtnText="Mark as Lost" setRefreshDocs={setTriggerRefreshToggle} />
                        <button className="ml-1 mr-1" onClick={handleReturn}>Return Document</button>
                    </fieldset>
                </div>
            </FolderLayout>

            <Footer />

            { modal() }
            { modalReturn() }

            { modalSuccess() }
        </div>
    );
}
 
export default Action;