import { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';

import { FormatCurrentDate } from './FormatDate';

import iconClose from "../images/icons/close.png";

const SendMemo = ({ effstatus, warningID, setReFetch, setDocDetails }) => {
    const [ sendMemoDetails, setSendMemoDetails ] = useState(null);
    const [ showModal, setShowModal ] = useState(false);
    const [ showPreviewModal, setShowPreviewModal ] = useState(false);
    const [ subject, setSubject ] = useState("");
    const [ message, setMessage ] = useState("");

    useEffect( () => {
        if(sendMemoDetails) {
            setSubject(sendMemoDetails.default_subject);
            setMessage(sendMemoDetails.default_message);
        }
    }, [sendMemoDetails] );

    const handleSendMemo = () => {
        setShowModal(true);

        fetch(`/api/dts-admin/send-memo/get-details/${warningID}`)
            .then( res => res.json() )
            .then( res => {
                if( 'send_memo_details' in res && res.send_memo_details ) {
                    setSendMemoDetails(res.send_memo_details);
                }
            })
        ;
    }

    const handleEmailMemo = () => {
        if( !window.confirm("Are you sure you want to send this memo as email?") ) return; //exit

        const details = {
            subject,
            message,
            to: sendMemoDetails.recipient_fullname,
            from: sendMemoDetails.sender_fullname,
            date: FormatCurrentDate(),
            sendMemoDetails
        }

        fetch(`/api/dts-admin/send-memo-as-email`,
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(details)
            }).then( res => res.json() )
            .then( res => {
                if( 'success' in res && res.success ) {
                    setReFetch( i => i + 1 );
                    setShowModal(false);
                    setDocDetails(null);
                } else alert("Something went wrong!");
            })
        ;
    }

    const handleShowPreview = () => {
        setShowPreviewModal(true);
        setShowModal(false);
    }

    const handleHidePreview = () => {
        setShowPreviewModal(false);
        setShowModal(true);
    }

    const handlePrintMemo = () => {
        if( !window.confirm("Are you sure you want to print this memo?") ) return; //exit

        //print
        var content = document.getElementById("forPrint");
        var winPrint = window.open('', 'Print', 'toolbar=0, location=0, scrollbars=1, resizable=1, width=500, height=500');

        winPrint.document.write(content.innerHTML);
        winPrint.document.close();
        winPrint.focus();
        winPrint.print();
        //winPrint.close();

        //update db
        const details = {
            subject,
            message,
            to: sendMemoDetails.recipient_fullname,
            from: sendMemoDetails.sender_fullname,
            date: FormatCurrentDate(),
            sendMemoDetails
        }

        fetch(`/api/dts-admin/print-memo`,
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(details)
            }).then( res => res.json() )
            .then( res => {
                if( 'success' in res && res.success ) {
                    alert("A new window along with the Print Dialog Box opened for you to adjust your settings and print.");
                    setReFetch( i => i + 1 );
                    setShowModal(false);
                    setDocDetails(null);
                } else alert("Something went wrong!");
            })
        ;
    }

    const modalPreview = () => {
        return (
            <Modal show={showPreviewModal} backdrop="static" keyboard={false} centered size="lg">
                <Modal.Body>
                    <div className="text-align-right">
                        <input type="image" src={iconClose} onClick={ handleHidePreview } />
                    </div>

                    <fieldset className="mt-2" style={{...s.fieldset, backgroundColor: 'white'}}>
                        <legend style={{...s.legend, backgroundColor: 'white'}}>Preview Memo</legend>

                        <div id="forPrint">
                            <h5>MEMORANDUM</h5>

                            { sendMemoDetails && 
                                <>
                                    <div className="mt-4"><strong>To:</strong> {sendMemoDetails.recipient_fullname}</div>
                                    <div className="mt-2"><strong>From:</strong> {sendMemoDetails.sender_fullname}</div>
                                    <div className="mt-2"><strong>Date:</strong> <FormatCurrentDate /></div>
                                    <div className="mt-2"><strong>Subject:</strong> {subject}</div>
                                    <div className="mt-3">
                                        <strong>Message:</strong>
                                        <br />
                                        <div style={{whiteSpace: "pre-line"}}>{message}</div>
                                        
                                    </div>
                                </>
                            }
                        </div>
                    </fieldset>

                    <div className="mt-3 mb-3 text-align-center">
                        <button className="ml-2" onClick={ handlePrintMemo }>Print</button>
                        <button className="ml-2" onClick={ handleEmailMemo }>E-mail Memo</button>
                        <button className="ml-2" onClick={ handleHidePreview }>Edit</button>
                        <button className="ml-2" onClick={ () => setShowPreviewModal(false) }>Cancel/Close</button>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }

    const modalSendMemo = () => {
        return (
            <Modal show={showModal} backdrop="static" keyboard={false} centered size="lg">
                <Modal.Body>
                    <div className="text-align-right">
                        <input type="image" src={iconClose} onClick={ () => setShowModal(false) } />
                    </div>
                    <fieldset className="mt-2" style={s.fieldset}>
                        <legend style={s.legend}>Memorandum</legend>
                        
                        { sendMemoDetails &&
                            <table style={s.table}>
                                <tr>
                                    <td style={s.tdLabel}>To:</td>
                                    <td style={s.tdInput}>
                                        <input value={sendMemoDetails.recipient_fullname} type="text" className="width-100" disabled/>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={s.tdLabel}></td>
                                    <td style={s.tdInput}>
                                        <input value={sendMemoDetails.dept_title} type="text" className="width-100" disabled/>
                                    </td>
                                </tr>

                                <tr>
                                    <td style={s.tdLabel}>From:</td>
                                    <td style={s.tdInput}>
                                        <input type="text" value={sendMemoDetails.sender_fullname} className="width-100" disabled/>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={s.tdLabel}></td>
                                    <td style={s.tdInput}>
                                        <input type="text" value={sendMemoDetails.sender_department} className="width-100" disabled/>
                                    </td>
                                </tr>

                                <tr>
                                    <td style={s.tdLabel}>CC:</td>
                                    <td style={s.tdInput}>
                                        <input type="text" className="width-100" disabled/>
                                    </td>
                                </tr>

                                <tr>
                                    <td style={s.tdLabel}>Subject:</td>
                                    <td style={s.tdInput}>
                                        <input type="text" value={subject} onChange={ (e) => setSubject(e.target.value) } className="width-100"/>
                                    </td>
                                </tr>

                                <tr>
                                    <td style={s.tdLabel}>Message:</td>
                                    <td style={s.tdInput}>
                                        <textarea className="width-100" rows="10" value={message} onChange={ (e) => setMessage(e.target.value) }></textarea>
                                    </td>
                                </tr>
                            </table>
                        }
                    </fieldset>

                    <div className="mt-3 mb-3 text-align-center">
                        <button className="ml-2" onClick={ handleShowPreview }>Preview</button>
                        <button className="ml-2" onClick={ () => setShowModal(false) }>Cancel/Close</button>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }

    return (
        <>
            <button className="ml-2" onClick={ handleSendMemo } disabled={ effstatus !== "For Memo" && effstatus !== "Printed/E-mailed Memo" ? true : false }>Send Memo</button>

            { modalSendMemo() }
            { modalPreview() }
        </>
    );
}

const s = {
    fieldset: {
        border: "1px solid rgb(201 201 201)",
        borderRadius: "8px",
        padding: "12px"
    },
    legend: {
        textAlign: "right",
        border: "1px solid rgb(201 201 201)",
        borderRadius: "2px",
        padding: "2px 4px"
    },
    table: {
        width: "100%"
    },
    tdLabel: {
        fontWeight: "bold",
        paddingRight: "4px",
        width: "12%",
        textAlign: "right",
        paddingTop: "6px"
    },
    tdInput: {
        width: "88%",
        paddingTop: "6px"
    }
};
 
export default SendMemo;