import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const EnsureAuthenticated = ({ children, isAuthenticated, reAuthToggle, setReAuthToggle }) => {
    const navigate = useNavigate();

    useEffect( () => setReAuthToggle( !reAuthToggle ), [] ); //trigger to recheck authentication

    if( isAuthenticated === false ) navigate('/');

    return <>{children}</>;
}
 
export default EnsureAuthenticated;