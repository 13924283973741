import { useState, useEffect } from "react";

import { FormatDate } from "./FormatDate";

import Accordion from 'react-bootstrap/Accordion';

const SentMemos = ({ warningID, effstatus }) => {
    const [ sentMemos, setSentMemos ] = useState([]);

    useEffect( () => {
        if( effstatus === "PEM" ) { //fetch only if printed/emailed memo
            fetch(`/api/dts-admin/get-sent-memos/${warningID}`)
                .then( res => res.json() )
                .then( res => {
                    if( 'sent_memos' in res && res.sent_memos ) {
                        setSentMemos( res.sent_memos );
                    } else alert("Something went wrong.");
                })
            ;
        }
        
    }, [] );

    return (
        <>
            { sentMemos.length > 0 &&
                <fieldset className="mt-3">
                    <legend>Sent Memos</legend>

                    <Accordion>
                        { sentMemos.map( (sm, i) => (
                            <Accordion.Item eventKey={i} key={i}>
                                <Accordion.Header>{ FormatDate(sm.memo_dt_2) } { sm.action_type === 'S' ? ' (E-mailed)' : ' (Printed)' }</Accordion.Header>
                                <Accordion.Body>
                                    <div className=""><strong>To:</strong> {sm.dept_head}</div>
                                    {/* <div className="mt-2">{sm.dept_title}</div> */}
                                    <div className="mt-2"><strong>From:</strong> {sm.from_name}</div>
                                    {/* <div className="mt-2">{sm.from_dept_title}</div> */}
                                    <div className="mt-2"><strong>Date:</strong> { FormatDate(sm.memo_dt_2) }</div>
                                    <div className="mt-2"><strong>Subject:</strong> {sm.memo_head}</div>
                                    <div className="mt-3">
                                        <strong>Message:</strong>
                                        <br />
                                        <div style={{whiteSpace: "pre-line"}}>{sm.memo_body}</div>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        ))}
                    </Accordion>

                </fieldset>
            }
        </>
        
    );
}

export default SentMemos;