import { useState, useEffect } from "react";
import isEmail from 'validator/lib/isEmail';

import FolderLayout from "../components/FolderLayout";
import Footer from '../components/Footer';
import Header from '../components/Header';

import iconError from '../images/icons/error.png';
import iconSuccess from '../images/icons/success.png'

const NewUser = ({ newUserProps }) => {
    const [ departments, setDepartments ] = useState([]);
    const [ roles, setRoles ] = useState([]);
    
    const [ lname, setLname ] = useState('');
    const [ fname, setFname ] = useState('');
    const [ mname, setMname ] = useState('');
    const [ gender, setGender ] = useState('');
    const [ department, setDepartment ] = useState('');
    const [ isDepartmentHead, setIsDepartmentHead ] = useState(false);
    const [ roleCode, setRoleCode ] = useState('');
    const [ role, setRole ] = useState('');
    const [ username, setUsername ] = useState('');

    const [ isUsernameDisabled, setIsUsernameDisabled ] = useState(false);
    const [ isDeptHeadDisabled, setIsDeptHeadDisabled ] = useState(false);

    const [ isLoading, setIsLoading ] = useState(false);
    const [ errors, setErrors ] = useState([]);
    const [ successResult, setSuccessResult ] = useState(null);
    const [ cancelText, setCancelText ] = useState('Cancel');
    const [ disableSave, setDisableSave ] = useState(false);

    useEffect(() => {
        fetch('/api/get-departments-and-roles')
            .then( res => res.json() )
            .then( res => {
                if( 'departments' in res ) setDepartments(res.departments);
                if( 'roles' in res ) setRoles(res.roles);
            })
        ;
    }, []);

    const handleDepartmentChange = (value) => {
        if( value !== "" ) {
            fetch(`/api/get-department-supervisor/${value}`)
                .then( res => res.json() )
                .then( res => {
                    if( res.results ) { //department has supervisor
                        setIsDepartmentHead(false);
                        setIsDeptHeadDisabled(true);
                    } else { //department has no supervisor
                        setIsDeptHeadDisabled(false);
                    }


                })
            ;
        } else {
            setIsDepartmentHead(false);
        }

        setDepartment(value);
    }

    const handleRoleChange = (value, text) => {
        //disable checkbox and username input box
        if(text === 'Messenger') {
            setIsDepartmentHead(false);
            setIsDeptHeadDisabled(true);

            setUsername('');
            setIsUsernameDisabled(true);
        } else {
            handleDepartmentChange(department);
            //setIsDeptHeadDisabled(false);
            setIsUsernameDisabled(false);
        }
        
        //set role
        setRoleCode(value);
        setRole(text);
    }

    const handleSubmit = () => {
        if( lname && fname && gender && department && roleCode && role && ( username || (!username && role === "Messenger") ) ) { //must have values
            if( isEmail(username) || (!username && role === "Messenger") ) { //username must be email
                setIsLoading(true);

                const userDetails = { lname, fname, mname, gender, department, roleCode, role, username, isDepartmentHead };

                fetch('/api/users',
                    {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(userDetails)
                    }).then( res => res.json() 
                    ).then(res => {
                        if( Array.isArray(res) ) { //returned an array with error message
                            setErrors( res );
                            setSuccessResult(null);
                        } else { //no error, registered successfully
                            setSuccessResult(res);
                            setErrors([]);
                            setCancelText("Clear");
                            setDisableSave(true);
                        }

                        setIsLoading(false);
                    })
                ;
            } else {
                setErrors([{ err_msg: 'Username must be a valid email address.' }]);
            }

        } else {
            setErrors([{ err_msg: 'Fields marked with asterisk (*) are required.' }]);
        }
    }

    const clear = () => {
        setLname('');
        setFname('');
        setMname('');
        setGender('');
        setDepartment('');
        setIsDepartmentHead(false);
        setRoleCode('');
        setRole('');
        setUsername('');

        setDisableSave(false);
        setSuccessResult(null);
        setErrors([]);
    }

    return (
        <div className="new-user container">
            <Header name={newUserProps.name} department={newUserProps.department} />

            <FolderLayout page="new-user">
                <fieldset>
                    <legend>Personal Data Entry</legend>
                    <div>
                        <label for="lname">* Last Name</label>
                        <input type="text" id="lname" value={lname} onChange={ (e) => setLname(e.target.value) } required />
                    </div>
                    <div className="mt-3">
                        <label for="fname">* First Name</label>
                        <input type="text" id="fname" value={fname} onChange={ (e) => setFname(e.target.value) } required />
                    </div>
                    <div className="mt-3">
                        <label for="mname">Middle Name</label>
                        <input type="text" id="mname" value={mname} onChange={ (e) => setMname(e.target.value) } />
                    </div>
                    <div className="mt-3">
                        <label>* Gender</label>
                        <select value={gender} onChange={ (e) => setGender(e.target.value) } required>
                            <option value=""></option>
                            <option value="M">Male</option>
                            <option value="F">Female</option>
                        </select>
                    </div>
                    <div className="mt-3">
                        <label for="department">* Department</label>
                        <select value={department} onChange={ (e) => handleDepartmentChange(e.target.value) } required>
                            <option value=""></option>
                            { departments.length > 0 && departments.map( (d, i) => (
                                <option key={i} value={d.dept_code}>{d.dept_title}</option>
                            ))}
                        </select>

                        <input type="checkbox" id="isDepartmentHead" className="ml-3" checked={isDepartmentHead} onChange={ (e) => setIsDepartmentHead(e.target.checked) } disabled={isDeptHeadDisabled} />
                        <label for="isDepartmentHead" className="adjust ml-1">Department Head</label>
                    </div>
                    <div className="mt-3">
                        <label>* Role</label>
                        <select value={roleCode} onChange={ (e) => handleRoleChange( e.target.value, e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text ) } required>
                            <option></option>
                            { roles.length > 0 && roles.map( (r, i) => (
                                <option key={i} value={r.role_code}>{r.descr}</option>
                            ))}
                        </select>
                    </div>
                    <div className="mt-3">
                        <label for="username">* Username/Email</label>
                        <input type="text" id="username" value={username} onChange={ (e) => setUsername(e.target.value) } disabled={isUsernameDisabled} required />
                    </div>

                    { errors.length > 0 &&
                        <div className="error">
                            <div className="mb-2">
                                <img src={iconError} alt="Error" />
                                <h3 className="ml-2">Error</h3>
                            </div>
                            { errors.map( (e) => (
                                <span key={e.err_msg}>{e.err_msg}</span>
                            ) ) }
                        </div>
                    }

                    { successResult &&
                        <div className="success">
                            <div className="mb-2">
                                <img src={iconSuccess} alt="Success" />
                                <h3 className="ml-2">Success</h3>
                            </div>
                            <div>Username: <b>{successResult.username}</b></div>
                            <div className="mt-2">Default Password: <b>{successResult.default_password}</b></div>
                        </div>
                    }
                </fieldset>

                <div className="buttons">
                    <button onClick={handleSubmit} disabled={disableSave}>Save</button>
                    <button className="ml-3" onClick={clear}>{cancelText}</button>
                </div>
            </FolderLayout>

            <Footer />
        </div>
    );
}
 
export default NewUser;