import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const BingoCard = ({ index, generateCardNumbers, remove, drawnNumbers, b, i, n, g, o, isMarkedB, isMarkedI, isMarkedN, isMarkedG, isMarkedO, setIsMarkedB, setIsMarkedI, setIsMarkedN, setIsMarkedG, setIsMarkedO, callIntervalID, startGame, seconds }) => {
    const [ isBingo, setIsBingo ] = useState(false);
    const [ winningPatern, setWinningPatern ] = useState({ horizontal: false, vertical: false, diagonal: false, horizontalNumbers: [], verticalNumbers: [], diagonalNumbers: [] });

    useEffect( () => {
        checkIfBingo();
    }, [isMarkedB, isMarkedI, isMarkedN, isMarkedG, isMarkedO]);

    const handleNumberClick = (num, i, set, isMarked) => {
        //validate number click

        if( drawnNumbers.includes(num) ) {
            let copy = [...isMarked];
            copy[index][i] = 2;

            set(copy);

            //set(prevState => prevState.map((item, idx) => idx === i ? 2 : item));
        }
    }

    const checkIfBingo = () => {
        //check vertical bingo
        if( isMarkedB[index][0] === 2 && isMarkedB[index][1] === 2 && isMarkedB[index][2] === 2 && isMarkedB[index][3] === 2 && isMarkedB[index][4] === 2 ) winner("vertical", [ "B" + b[index][0], "B" + b[index][1], "B" + b[index][2], "B" + b[index][3], "B" + b[index][4] ] );
        if( isMarkedI[index][0] === 2 && isMarkedI[index][1] === 2 && isMarkedI[index][2] === 2 && isMarkedI[index][3] === 2 && isMarkedI[index][4] === 2 ) winner("vertical", [ "I" + i[index][0], "I" + i[index][1], "I" + i[index][2], "I" + i[index][3], "I" + i[index][4] ] );
        if( isMarkedN[index][0] === 2 && isMarkedN[index][1] === 2 && isMarkedN[index][3] === 2 && isMarkedN[index][4] === 2 ) winner("vertical", [ "N" + n[index][0], "N" + n[index][1], "N" + n[index][3], "N" + n[index][4] ] );
        if( isMarkedG[index][0] === 2 && isMarkedG[index][1] === 2 && isMarkedG[index][2] === 2 && isMarkedG[index][3] === 2 && isMarkedG[index][4] === 2 ) winner("vertical", [ "G" + g[index][0], "G" + g[index][1], "G" + g[index][2], "G" + g[index][3], "G" + g[index][4] ] );
        if( isMarkedO[index][0] === 2 && isMarkedO[index][1] === 2 && isMarkedO[index][2] === 2 && isMarkedO[index][3] === 2 && isMarkedO[index][4] === 2 ) winner("vertical", [ "O" + o[index][0], "O" + o[index][1], "O" + o[index][2], "O" + o[index][3], "O" + o[index][4] ] );

        //check horizontal bingo
        if( isMarkedB[index][0] === 2 && isMarkedI[index][0] === 2 && isMarkedN[index][0] === 2 && isMarkedG[index][0] === 2 && isMarkedO[index][0] === 2 ) winner("horizontal", [ "B" + b[index][0], "I" + i[index][0], "N" + n[index][0], "G" + g[index][0], "O" + o[index][0] ] );
        if( isMarkedB[index][1] === 2 && isMarkedI[index][1] === 2 && isMarkedN[index][1] === 2 && isMarkedG[index][1] === 2 && isMarkedO[index][1] === 2 ) winner("horizontal", [ "B" + b[index][1], "I" + i[index][1], "N" + n[index][1], "G" + g[index][1], "O" + o[index][1] ] );
        if( isMarkedB[index][2] === 2 && isMarkedI[index][2] === 2 && isMarkedG[index][2] === 2 && isMarkedO[index][2] === 2 ) winner("horizontal", [ "B" + b[index][2], "I" + i[index][2], "G" + g[index][2], "O" + o[index][2] ] );
        if( isMarkedB[index][3] === 2 && isMarkedI[index][3] === 2 && isMarkedN[index][3] === 2 && isMarkedG[index][3] === 2 && isMarkedO[index][3] === 2 ) winner("horizontal", [ "B" + b[index][3], "I" + i[index][3], "N" + n[index][3], "G" + g[index][3], "O" + o[index][3] ] );
        if( isMarkedB[index][4] === 2 && isMarkedI[index][4] === 2 && isMarkedN[index][4] === 2 && isMarkedG[index][4] === 2 && isMarkedO[index][4] === 2 ) winner("horizontal", [ "B" + b[index][4], "I" + i[index][4], "N" + n[index][4], "G" + g[index][4], "O" + o[index][4] ] );

        //check diagonal bingo
        if( isMarkedB[index][0] === 2 && isMarkedI[index][1] === 2 && isMarkedG[index][3] === 2 && isMarkedO[index][4] === 2 ) winner("diagonal", [ "B" + b[index][0], "I" + i[index][1], "G" + g[index][3], "O" + o[index][4] ]);
        if( isMarkedB[index][4] === 2 && isMarkedI[index][3] === 2 && isMarkedG[index][1] === 2 && isMarkedO[index][0] === 2 ) winner("diagonal", [ "B" + b[index][4], "I" + i[index][3], "G" + g[index][1], "O" + o[index][0] ]);
    }

    const winner = (pattern, numbers) => {
        let audio = new Audio( require('../audio/bingo.mp3') );
        audio.play();

        setIsBingo(true);

        if( pattern === "vertical" ) {
            setWinningPatern({ ...winningPatern, vertical: true, verticalNumbers: numbers });
        }

        if( pattern === "horizontal" ) {
            setWinningPatern({ ...winningPatern, horizontal: true, horizontalNumbers: numbers });
        }

        if( pattern === "diagonal" ) {
            setWinningPatern({ ...winningPatern, diagonal: true, diagonalNumbers: numbers });
        }
        
        clearInterval(callIntervalID);
    }

    return (
        <div style={{ display: 'flex', margin: '0 10px' }}>
            <div>
                <div className="bingo-card">
                    { b.length > 1 && !startGame &&
                        <Link to="" onClick={ () => remove(index) }>
                            <div className="bingo-remove-card">X</div>
                        </Link>
                    }

                    <div className="bingo-letters">
                        <div className={`bingo-space ${isBingo ? "winner-b" : ""}`}>B</div>
                        <div className={`bingo-space ${isBingo ? "winner-i" : ""}`}>I</div>
                        <div className={`bingo-space ${isBingo ? "winner-n" : ""}`}>N</div>
                        <div className={`bingo-space ${isBingo ? "winner-g" : ""}`}>G</div>
                        <div className={`bingo-space ${isBingo ? "winner-o" : ""}`}>O</div>
                    </div>

                    { startGame && seconds === 0 &&
                        <div className="bingo-numbers">
                            <div className="bingo-column">
                                { b[index].map( (b, ind) => (
                                    <Link key={ind} to="" className="bingo-number-button" onClick={ () => handleNumberClick("B" + b, ind, setIsMarkedB, isMarkedB) }>
                                        <div className={`bingo-space ${startGame ? "bingo-number-space" : "" } ${isMarkedB[index][ind] === 2 ? "bingo-number-space-marked" : isMarkedB[index][ind] === 1 ? "bingo-number-space-called" : "" }` }>{b}</div>
                                    </Link>
                                )) }
                            </div>
                            <div className="bingo-column">
                                { i[index].map( (i, ind) => (
                                    <Link key={ind} to="" className="bingo-number-button" onClick={ () => handleNumberClick("I" + i, ind, setIsMarkedI, isMarkedI) }>
                                        <div className={`bingo-space ${startGame ? "bingo-number-space" : "" } ${isMarkedI[index][ind] === 2 ? "bingo-number-space-marked" : isMarkedI[index][ind] === 1 ? "bingo-number-space-called" : "" }` }>{i}</div>
                                    </Link>
                                )) }
                            </div>
                            <div className="bingo-column">
                                { n[index].map( (n, ind) => (
                                    <Link key={ind} to="" className="bingo-number-button" onClick={ () => handleNumberClick("N" + n, ind, setIsMarkedN, isMarkedN) }>
                                        <div className={`bingo-space ${startGame ? "bingo-number-space" : "" } ${isMarkedN[index][ind] === 2 ? "bingo-number-space-marked" : isMarkedN[index][ind] === 1 ? "bingo-number-space-called" : "" }` }>{ ind === 2 ? '*' : n }</div>
                                    </Link>
                                )) }
                            </div>
                            <div className="bingo-column">
                                { g[index].map( (g, ind) => (
                                    <Link key={ind} to="" className="bingo-number-button" onClick={ () => handleNumberClick("G" + g, ind, setIsMarkedG, isMarkedG) }>
                                        <div className={`bingo-space ${startGame ? "bingo-number-space" : "" } ${isMarkedG[index][ind] === 2 ? "bingo-number-space-marked" : isMarkedG[index][ind] === 1 ? "bingo-number-space-called" : "" }` }>{g}</div>
                                    </Link>
                                )) }
                            </div>
                            <div className="bingo-column">
                                { o[index].map( (o, ind) => (
                                    <Link key={ind} to="" className="bingo-number-button" onClick={ () => handleNumberClick("O" + o, ind, setIsMarkedO, isMarkedO) }>
                                        <div className={`bingo-space ${startGame ? "bingo-number-space" : "" } ${isMarkedO[index][ind] === 2 ? "bingo-number-space-marked" : isMarkedO[index][ind] === 1 ? "bingo-number-space-called" : "" }` }>{o}</div>
                                    </Link>
                                )) }
                            </div>
                        </div>
                    }

                    { seconds !== 0 &&
                        <div className="bingo-numbers">
                            <div className="bingo-column">
                                { b[index].map( (b) => (
                                    <div className="bingo-space">{b}</div>
                                )) }
                            </div>
                            <div className="bingo-column">
                                { i[index].map( (i) => (
                                    <div className="bingo-space">{i}</div>
                                )) }
                            </div>
                            <div className="bingo-column">
                                { n[index].map( (n, i) => (
                                    <div className="bingo-space">{ i === 2 ? '*' : n }</div>
                                )) }
                            </div>
                            <div className="bingo-column">
                                { g[index].map( (g) => (
                                    <div className="bingo-space">{g}</div>
                                )) }
                            </div>
                            <div className="bingo-column">
                                { o[index].map( (o) => (
                                    <div className="bingo-space">{o}</div>
                                )) }
                            </div>
                        </div>
                    }
                </div>

                { ( winningPatern.horizontal || winningPatern.vertical || winningPatern.diagonal ) &&
                    <div style={{marginTop: '10px', textAlign: 'center', fontWeight: 'bold'}}>
                        <div>Winning Pattern</div>

                        { winningPatern.horizontal && 
                            <>
                                <div>Horizontal</div>
                                <div>{winningPatern.horizontalNumbers.join(", ")}</div>
                            </> 
                        }
                        { winningPatern.vertical && 
                            <>
                                <div>Vertical</div>
                                <div>{winningPatern.verticalNumbers.join(", ")}</div>
                            </> 
                        }
                        { winningPatern.diagonal && 
                            <>
                                <div>Diagonal</div>
                                <div>{winningPatern.diagonalNumbers.join(", ")}</div>
                            </>
                        }
                    </div>
                }

                <div className="bingo-button-container">
                    <button onClick={ () => generateCardNumbers(index) } disabled={startGame}>Generate new numbers</button>
                </div>
            </div>

            
        </div>
    );
}
 
export default BingoCard;