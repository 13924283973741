import { Link } from "react-router-dom";

const DocDetailsForProcessor = ({ docDetails }) => {
    return (
        <fieldset className="mt-4">
            <legend>Document Details</legend>

            <div style={s.container}>
                <table style={s.table}>
                    <tr style={s.tr}>
                        <td style={s.tdLeft}>Doc. Ref. Num.:</td>
                        <td>{docDetails.doc_id}</td>
                    </tr>
                    <tr style={s.tr}>
                        <td style={s.tdLeft}>Forwarded By:</td>
                        <td>{docDetails.dept_title}</td>
                    </tr>
                    { docDetails.out_dt &&
                        <tr style={s.tr}>
                            <td style={s.tdLeft}>Date Forwarded:</td>
                            <td>{docDetails.out_dt}</td>
                        </tr>
                    }
                    <tr style={s.tr}>
                        <td style={s.tdLeft}>Messenger:</td>
                        <td>{docDetails.messenger_name}</td>
                    </tr>
                    <tr style={s.tr}>
                        <td style={s.tdLeft}>Requestor:</td>
                        <td>{docDetails.requesting_office}</td>
                    </tr>
                    <tr style={s.tr}>
                        <td style={s.tdLeft}>Document Title:</td>
                        <td>{docDetails.title}</td>
                    </tr>
                    <tr style={s.tr}>
                        <td style={s.tdLeft}>Document Description:</td>
                        <td>{docDetails.descr}</td>
                    </tr>
                    <tr style={s.tr}>
                        <td style={s.tdLeft}>Notes:</td>
                        <td>{docDetails.remarks}</td>
                    </tr>
                    <tr style={{...s.tr, borderBottom: 'none'}}>
                        <td style={s.tdLeft}>Remarks/Instructions:</td>
                        <td>{docDetails.instructions}</td>
                    </tr>

                    {/* { from !== "action" &&
                        <tr style={{...s.tr, borderBottom: 'none'}}>
                            <td style={s.tdLeft}>Attachments:</td>
                            <td>
                                { !docDetails.attachments && <i>No attachments</i>}
                            </td>
                        </tr>
                    }

                    { from === "action" &&
                        <tr style={{...s.tr, borderBottom: 'none', lineHeight: '1.3rem'}}>
                            <td style={s.tdLeft}>
                                Attachments: <br />
                                <Link to="#">
                                    Add Attachment/ <br />Supporting Docs.
                                </Link>
                            </td>
                            <td>
                                { !docDetails.attachments && <i>No attachments</i>}
                            </td>
                        </tr>
                    } */}
                    
                    
                </table>
            </div>
        </fieldset>
    );
}

const s = { //styles
    container: {
        backgroundColor: 'rgb(234 248 255)',
        width: '100%',
        padding: '5px 10px',
        border: '1px solid #bbb',
        borderRadius: '10px'
    },
    table: {
        width: '100%',
    },
    tr: {
        borderBottom: '1px solid #bbb',
        lineHeight: '2rem'
    },
    tdLeft: {
        textAlign: 'right',
        paddingRight: '6px',
        width: '25%',
        fontWeight: 'bold'
    }
}
 
export default DocDetailsForProcessor;