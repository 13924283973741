import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';

import DocumentsForReceiving from "../components/DocumentsForReceiving";
import FolderLayout from "../components/FolderLayout";
import Footer from "../components/Footer";
import Header from "../components/Header";

import iconRefresh from '../images/icons/refresh.png';
import iconReturn from '../images/icons/return.png';
import iconClose from "../images/icons/close.png";
import iconSuccess from '../images/icons/success.png';

const Receiving = ({ properties: p }) => {
    const [ forReceivingDocs, setForReceivingDocs ] = useState([]);
    const [ triggerRefreshToggle, setTriggerRefreshToggle ] = useState(false);

    const [ checkedDocs, setCheckedDocs ] = useState([]);

    const [ showModal, setShowModal ] = useState(false);
    const [ showModalSuccess, setShowModalSuccess ] = useState("");

    const [ senderDept, setSenderDept ] = useState("");
    const [ senderDeptCode, setSenderDeptCode ] = useState("");
    const [ messengers, setMessengers ] = useState([]);
    const [ messengerCode, setMessengerCode ] = useState('');
    const [ returnPhone, setReturnPhone ] = useState('');
    const [ returnReason, setReturnReason ] = useState('');
    const [ docID, setDocID ] = useState("");

    useEffect( () => {
        fetch('/api/dts-receiving')
            .then( res => res.json() )
            .then( res => {
                if( 'documents_for_receiving' in res ) { //for receiving docs are available
                    setForReceivingDocs(res.documents_for_receiving);
                }
            })
        ;
    }, [triggerRefreshToggle]);

    const handleReceive = (docIDs) => {
        let isQuickReceive = false;

        if( docIDs === undefined || docIDs === "" ) { //undefined if not quick receive
            docIDs = [...checkedDocs];
        } else {
            isQuickReceive = true;
            docIDs = [docIDs];
        }

        if( isQuickReceive || docIDs.length > 0 ) {
            fetch('/api/dts-receiving/receive',
                {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ docIDs })
                }).then( res => res.json() )
                .then( res => {
                    if( 'msg' in res ) {
                        setShowModalSuccess("You have successfully received the selected document(s).");
                        setTriggerRefreshToggle( !triggerRefreshToggle );
                    } else alert("Unable to process request.");
                })
            
            ;
        } else alert("Please select document to receive.");
    }

    const handleReturn = () => {
        let sender_deptcd = "";

        if( checkedDocs.length === 0 ) alert("Please select a document to return.");
        else if( checkedDocs.length > 1 ) alert("Cannot return multiple documents.");
        else if( checkedDocs.length === 1 ) {
            for( let i = 0; i < forReceivingDocs.length; i++ ) {
                if( forReceivingDocs[i].doc_id === checkedDocs[0] ) {
                    sender_deptcd = forReceivingDocs[i].sender_deptcd;
                    setSenderDeptCode(sender_deptcd);
                    setSenderDept( forReceivingDocs[i].dept_title );
                }
            }

            fetch(`/api/dts-main-document/get-list-of-messengers/${sender_deptcd}`)
                .then( res => res.json() )
                .then( res => {
                    if( res.names ) setMessengers(res.names);
                    else setMessengers([]);
                })
            ;

            setShowModal(true);
        }
    }

    const handleExecuteReturn = () => {
        if( messengerCode !== "" ) {
            fetch(`/api/dts-receiving/return/${checkedDocs[0]}`,
                {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ senderDeptCode, messengerCode, remarks: returnPhone + " | " + returnReason, reason: returnReason })
                }).then( res => res.json() )
                .then( res => {
                    if( 'msg' in res ) {
                        setShowModalSuccess(`You have successfully returned the document to ${senderDept}.`);
                        setShowModal(false);
                        setTriggerRefreshToggle( !triggerRefreshToggle );
                    }
                })
            ;
        } else alert("Please select a messenger.");
    }

    const handleKeyDown = (docIDs, event) => {
        if (event.key === 'Enter') {
            handleReceive(docIDs);
        }
    }

    const modalSuccess = () => {
        return (
            <Modal show={showModalSuccess} onHide={ () => setShowModalSuccess("") } backdrop="static" keyboard={false} centered>
                <Modal.Body>
                    <div className="text-align-right">
                        <input type="image" src={iconClose} onClick={ () => setShowModalSuccess("") } />
                    </div>
                    <div className="mt-4 mb-3">
                        <div className="mb-2 text-align-center">
                            <img src={iconSuccess} alt="Success" />
                            <span className="ml-2 bold" style={{ color: 'rgb(0, 72, 255)', fontSize: '1.2rem' }}>{showModalSuccess}</span>
                        </div>
                    </div>
                    <div className="mb-3 text-align-center">
                        <button className="ml-2" onClick={ () => setShowModalSuccess("") }>Okay</button>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }

    const modal = () => {
        return (
            <Modal show={showModal} onHide={ () => setShowModal(false) } backdrop="static" keyboard={false} centered>
                <Modal.Body>
                    <div className="text-align-right">
                        <input type="image" src={iconClose} onClick={ () => setShowModal(false) } />
                    </div>

                    <div className="d-flex justify-content-end align-items-center mt-3">
                        <label className="text-align-right">Destination</label>
                        <input type="text" className="width-60 ml-2" value={senderDept} disabled />
                    </div>

                    <div className="d-flex justify-content-end align-items-center mt-3">
                        <label className="text-align-right">Messenger</label>
                        <select value={messengerCode} onChange={ (e) => setMessengerCode(e.target.value) } className="width-60 ml-2">
                            <option value=""></option>
                            { messengers.length > 0 && messengers.map( (n, i) => (
                                <option key={i} value={n.userid}>{n.lastname}, {n.firstname}</option>
                            ) ) }
                        </select>
                    </div>

                    <div className="d-flex justify-content-end align-items-center mt-3">
                        <label className="text-align-right">Informed Thru Phone Call</label>
                        <input type="text" className="width-60 ml-2" value={returnPhone} onChange={ (e) => setReturnPhone(e.target.value) } />
                    </div>


                    <div className="d-flex justify-content-end align-items-center mt-3">
                        <label className="text-align-right">Reason <br /> Lacking Documents</label>
                        <textarea rows="4" className="width-60 ml-2" value={returnReason} onChange={ (e) => setReturnReason(e.target.value) }></textarea>
                    </div>

                    <div className="d-flex justify-content-end align-items-center mt-3 mb-4">
                        <label></label>
                        <div className="width-60 ml-2">
                            <button onClick={handleExecuteReturn}>Return</button>
                            <button className="ml-2" onClick={ () => setShowModal(false) }>Cancel</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    };

    return (
        <div className="container">
            <Header name={p.name} department={p.department} />

            <FolderLayout page="receiving">
                <div className="receiving mt-2">
                    <fieldset className="d-flex justify-content-between">
                        <div>
                            <label htmlFor="ref-num" className="bold" style={{width: 'unset'}}>Reference Number:</label>
                            <input type="text" value={docID} onChange={ (e) => setDocID(e.target.value) } onKeyDown={ (e) => handleKeyDown(docID, e) } id="ref-num" style={{width: 'unset'}} />
                        </div>
                        <div className="d-flex align-items-center">
                            <img src={iconRefresh} alt="Refresh" />
                            <Link to="#" onClick={ () => setTriggerRefreshToggle( !triggerRefreshToggle ) } className="bold ml-2">Refresh for new incoming documents</Link>
                        </div>
                    </fieldset>

                    <fieldset className="mt-3">
                        <legend>Status Legend</legend>
                        <img src={iconReturn} alt="Return" />
                        <span className="ml-2">- Returned Document</span>
                    </fieldset>

                    <fieldset className="mt-3">
                        <legend>Documents for Receiving</legend>

                        <DocumentsForReceiving forReceivingDocs={forReceivingDocs} setCheckedDocs={setCheckedDocs} />
                        
                        <div className="text-align-center">
                            <span className="bold">Note: Multiple document selection is not allowed for "returning" documents.</span>
                        </div>
                    </fieldset>

                    <fieldset className="mt-3">
                        <legend>Controls</legend>
                        
                        <div className="text-align-center">
                            <button className="mr-2" onClick={ () => handleReceive() }>Receive</button>
                            <button onClick={handleReturn}>Return Document</button>
                        </div>
                    </fieldset>
                </div>
            </FolderLayout>

            <Footer />

            { modalSuccess() }
            { modal() }
        </div>
    );
}

export default Receiving;